import React from 'react';
import { useDispatch } from 'react-redux';

import { FormSubmitButton, Button } from 'components';
import { closeModal } from 'modules/Modal/redux/modalSlice';

import { ButtonsWrapper, ButtonControl } from './AddTeamModalStyles';

function Footer({ isValid, isSubmitting }) {
  const dispatch = useDispatch();

  const handleCancel = () => dispatch(closeModal());

  return (
    <ButtonsWrapper>
      <ButtonControl>
        <Button text="Cancel" onClickAction={handleCancel} />
      </ButtonControl>
      <ButtonControl>
        <FormSubmitButton
          isFetching={isSubmitting}
          disabled={isSubmitting || !isValid}
          text="Invite"
        />
      </ButtonControl>
    </ButtonsWrapper>
  );
}

export default Footer;
