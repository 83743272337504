import styled from 'styled-components';
import { colors } from 'styles';

export const WorkItemsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  margin-right: 10px;
  position: absolute;
  padding: 10px 10px 10px 0px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    backgtound-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.BRAND_COLOR};
    border: 1px solid ${colors.BRAND_COLOR};
    border-radius: 30px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 80px;
  margin-bottom: 20px;
  position: relative;
`;

export const NotificationDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50$;
  background-color: ${colors.ERROR};
`;
