import { colors } from 'styles';
import {
  FeedbackIconStyled,
  AddIconStyled,
} from 'modules/Dashboard/components/WorkItem/WorkItemStyles';

export const FEEDBACK_STATUS = {
  submitted: {
    title: 'Feedback Submitted',
    color: colors.SECONDARY_TEXT,
    icon: FeedbackIconStyled,
  },
  saved: {
    title: 'Feedback Drafted',
    color: colors.ACCOMPLISHMENT_TAG,
    icon: AddIconStyled,
  },
  requested: {
    title: 'Feedback Requested',
    color: colors.ERROR,
    icon: AddIconStyled,
  },
};
