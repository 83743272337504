import { createSelector } from '@reduxjs/toolkit';

const authSelector = (state) => state.authProvider;

export const getAuthenticatedStatus = createSelector(
  authSelector,
  ({ isAuthenticated }) => isAuthenticated,
);

export const getAuthenticatedToken = createSelector(
  authSelector,
  ({ token }) => token,
);

export const getPersonId = createSelector(
  authSelector,
  ({ personId }) => personId,
);

export const getCompanyId = createSelector(
  authSelector,
  ({ companyId }) => companyId,
);
