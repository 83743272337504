import styled from 'styled-components';

import { breakpoints } from 'styles';

export const PositionInfoFormContainer = styled.form`
  input[name='salary'] {
    padding-left: 10px !important;
  }

  > div:first-child {
    div:nth-child(4) {
      .MuiFormControl-root {
        .MuiInputBase-root {
          &::before {
            position: absolute;
            content: '$';
            top: 42%;
            left: 0;
          }
        }
      }
    }
  }
`;

export const PickersControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row;
    flex-wrap: wrap;

    > div:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media (min-width: 1320px) {
    > div:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const DatePickerContainer = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    width: calc(50% - 10px);
  }

  @media (min-width: 1320px) {
    width: 185px;
  }
`;
