import { createSelector } from '@reduxjs/toolkit';

const personInfoSelector = (state) => state.profile;

export const getProfileData = createSelector(
  personInfoSelector,
  (profileData) => profileData,
);

export const getPersonInitials = createSelector(
  personInfoSelector,
  ({ firstName, lastName }) => firstName.charAt(0).concat(lastName.charAt(0)),
);

export const getPersonFullName = createSelector(
  personInfoSelector,
  ({ firstName, lastName }) => ({ firstName, lastName }),
);

export const getPersonEmail = createSelector(
  personInfoSelector,
  ({ email }) => email,
);

export const getBasicProfileInfo = createSelector(
  personInfoSelector,
  ({ firstName, lastName, email }) => ({ firstName, lastName, email }),
);

export const getCurrentStepSelector = createSelector(
  personInfoSelector,
  ({ currentStep }) => currentStep,
);

export const isProfileDataInitialized = createSelector(
  personInfoSelector,
  ({ isInitialized }) => isInitialized,
);

export const getDemographicsData = createSelector(
  personInfoSelector,
  ({ demographics }) => demographics,
);

export const getPassedSteps = createSelector(
  personInfoSelector,
  ({ passedSteps }) => passedSteps,
);

export const getHasDisabilityFlag = createSelector(
  personInfoSelector,
  ({ demographics: { hasDisability } }) => hasDisability,
);

export const getDisabilities = createSelector(
  personInfoSelector,
  ({ disability }) => disability,
);

export const getPositionInfoData = createSelector(
  personInfoSelector,
  ({ positionInfo }) => positionInfo,
);

export const getLinkedinData = createSelector(
  personInfoSelector,
  ({ linkedin }) => ({ linkedin }),
);

export const isIncompleteStepSet = createSelector(
  personInfoSelector,
  ({ isFirstIncompleteStepSet }) => isFirstIncompleteStepSet,
);

export const getIsAccountFull = createSelector(
  personInfoSelector,
  ({ isAccountFull }) => isAccountFull,
);

export const getAccountOccupancy = createSelector(
  personInfoSelector,
  ({ accountOccupancy }) => accountOccupancy,
);
