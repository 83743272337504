import styled from 'styled-components';

import { space, colors, breakpoints, typography } from 'styles';

import { FormTitleText } from 'elements';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
  padding: ${space.XS};
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }

  @media (min-width: ${breakpoints.MD}) {
    max-width: 100%;
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 472px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  align-self: center;
  min-width: 182px;
  margin-bottom: ${space.LG};
  color: ${colors.MILESTONE_TAG};
`;

export const ButtonControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
`;
