import {
  MongoLogo,
  AcceleratorUCLALogo,
  LowellMilkenUCLALogo,
  Paragon,
  NSF,
  GCP,
  AWS,
} from 'assets';

const sponsorList = [
  {
    key: 'UCLA',
    src: AcceleratorUCLALogo,
    alt: 'UCLA Anderson Accelerator',
    link:
      'https://www.anderson.ucla.edu/centers/price-center-for-entrepreneurship-and-innovation/anderson-venture-accelerator',
  },
  {
    key: 'SHRM Paragon Labs',
    src: Paragon,
    alt: 'Paragon Labs @ SHRM',
    link: 'https://shrm.org',
  },
  {
    key: 'NSF',
    src: NSF,
    alt: 'National Science Foundation',
    link: 'https://nsf.gov',
  },
  {
    key: 'mongodb',
    src: MongoLogo,
    alt: 'Mongo for Startups',
    link: 'https://mongodb.com',
  },
  {
    key: 'LowellMilken',
    src: LowellMilkenUCLALogo,
    alt: 'Lowell Milken Institute, Sandler Prize -- UCLA',
    link:
      'https://lowellmilkeninstitute.law.ucla.edu/events/programs/lowell-milken-institute-sandler-prize/',
  },
  {
    key: 'GCP',
    src: GCP,
    alt: 'Google Cloud for Startups',
    link: 'https://cloud.google.com/developers/startups',
  },
  {
    key: 'AWS',
    src: AWS,
    alt: 'AWS for startups',
    link: 'https://aws.amazon.com/activate/',
  },
];

export default sponsorList;
