/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Select from 'react-select';

import { colors, typography, space, breakpoints } from 'styles';

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const selectStyles = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    margin: '0',
    marginTop: `${space.XXS}`,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: `34px`,
    height: `36px`,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    borderBottom: `2px solid ${colors.OUTLINES}`,
    '&:hover, &:focus, &:checked, &:enabled, &:active, &:focus-within': {
      borderBottom: `2px solid ${colors.OUTLINES}`,
      cursor: 'pointer',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: '-8px',
    fontFamily: `${typography.font.TEXT}`,
    opacity: '0.5',
    color: `${colors.SECONDARY_TEXT}`,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: '0',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${colors.PRIMARY}`,
    '&:hover': {
      color: `${colors.OUTLINES}`,
    },
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: '-8px',
    fontFamily: `${typography.font.TEXT}`,
    color: `${colors.PRIMARY}`,
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '5px',
    backgroundColor: `${colors.WHITE}`,
    boxShadow: `0px 8px 20px ${colors.OUTLINES}`,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '200px',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    fontFamily: `${typography.font.TEXT}`,
    color: `${colors.SECONDARY_TEXT}`,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? `${colors.OUTLINES}`
      : isFocused
      ? `${colors.WHITE}`
      : null,
    '&:hover, &:focus, &:checked, &:enabled, &:active, &:focus-within': {
      backgroundColor: `${colors.OUTLINES}`,
      color: `${colors.PRIMARY}`,
    },
    cursor: 'pointer',
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: '-8px',
    fontFamily: `${typography.font.TEXT}`,
    color: `${colors.PRIMARY}`,
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontFamily: `${typography.font.TEXT}`,
    color: `${colors.PRIMARY}`,
  }),
};

export const Selector = styled(Select)`
  > div {
    border-bottom: 2px solid
      ${(props) =>
        props.isError
          ? colors.ERROR
          : props.success === 'success'
          ? colors.BRAND_COLOR
          : colors.OUTLINES};
  }
`;

export const ErrorWrapper = styled.div`
  height: 20px;
  width: 100%;
  margin-bottom: ${space.XS};
`;

export const SelectLabel = styled.label`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }
`;
