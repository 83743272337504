import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkingOn from 'modules/Dashboard/MyWork/components/WorkingOn';
import { actionTypes } from 'modules/Dashboard/MyWork/redux/myWorkSlice';
import { getIsAccountFull } from 'modules/Profile/redux/selectors';
import {
  getMyWorkLoading,
  getEditingStatus,
} from 'modules/Dashboard/MyWork/redux/selectors';
import { pageRoutes } from 'modules/Navigation/constants';

import {
  DashboardBasis,
  NotificationField,
} from 'modules/Dashboard/components';
import LoadingState from 'modules/Dashboard/components/LoadingState';
import { ContentContainer } from 'modules/Dashboard/components/SharedStyledComponents';
import MyWorkContent from './MyWorkContent';

function MyWorkDashboard() {
  const dispatch = useDispatch();
  const isAccountFull = useSelector(getIsAccountFull);
  const isMyWorkLoading = useSelector(getMyWorkLoading);
  const isEditing = useSelector(getEditingStatus);

  const addWork = () => dispatch(actionTypes.openWorkItemModal());

  useEffect(() => {
    dispatch(actionTypes.getMyWorkRequest());
  }, [dispatch]);
  useEffect(() => {
    // GTAG
    gtag('event', 'page_view', {
      page_title: 'My Work',
      page_path: window.location.origin + window.location.pathname,
    });
  });
  return (
    <DashboardBasis>
      <WorkingOn handleWorkingOnClick={addWork} isEditing={isEditing} />
      <ContentContainer>
        {isMyWorkLoading ? <LoadingState /> : <MyWorkContent />}
      </ContentContainer>
      {!isAccountFull && <NotificationField link={pageRoutes.PROFILE} />}
    </DashboardBasis>
  );
}

export default MyWorkDashboard;
