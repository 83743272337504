export const font = {
  SITE_NAME: 'Lato-Black',
  TITLE: 'Lato-Bold',
  TEXT: 'Lato-Regular',
};

export const size = {
  XXS: '12px',
  XS: '14px',
  SM: '16px',
  MD: '18px',
  MDLG: '22px',
  LG: '30px',
  XL: '50px',
};
