import React from 'react';

import {
  IntroSectionWrapper,
  IntroSectionContainer,
  LogoContainer,
  Logo,
  LogoTitle,
  TitleWrapper,
  Title,
  DescriptionWrapper,
  DescriptionText,
} from './CreateCompanyIntroStyles';

function CreateCompanyIntro() {
  return (
    <IntroSectionWrapper>
      <IntroSectionContainer>
        <LogoContainer>
          <Logo />
          <LogoTitle>evalueme.ai</LogoTitle>
        </LogoContainer>
        <TitleWrapper>
          <Title>We don’t recognize your company yet, but we want to!</Title>
        </TitleWrapper>
        <DescriptionWrapper>
          <DescriptionText>
            Please provide additional information so we can verify your identity
            and employer, in order to protect privacy and security for all our
            users.
          </DescriptionText>
        </DescriptionWrapper>
      </IntroSectionContainer>
    </IntroSectionWrapper>
  );
}

export default CreateCompanyIntro;
