import waitlistReducer from 'modules/Waitlist/redux/waitListSlice';
import authReducer from 'modules/Auth/redux/authSlice';
import toastReducer from 'modules/Toast/redux/toastSlice';
import modalReducer from 'modules/Modal/redux/modalSlice';
import dashboardReducer from 'modules/Dashboard/redux/dashboardSlice';
import profileReducer from 'modules/Profile/redux/profileSlice';
import authProviderReducer from 'modules/Auth/AuthProvider/redux/authProviderSlice';
import navigationReducer from 'modules/Navigation/redux/navigationSlice';
import contactUsReducer from 'modules/ContactUs/redux/contactUsSlice';
import recaptchaReducer from 'modules/Recaptcha/redux/recaptchaSlice';
import feedbackReducer from 'modules/Feedback/redux/feedbackSlice';

const rootReducer = {
  waitlist: waitlistReducer,
  auth: authReducer,
  toast: toastReducer,
  modal: modalReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
  authProvider: authProviderReducer,
  navigation: navigationReducer,
  contactUs: contactUsReducer,
  recaptcha: recaptchaReducer,
  feedback: feedbackReducer,
};

export default rootReducer;
