import { createSlice } from '@reduxjs/toolkit';

const loginState = {
  isFetching: false,
};

const loginSlice = createSlice({
  name: 'AUTH',
  initialState: loginState,
  reducers: {
    loginRequest: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state) => {
      gtag('event', 'logged_in', {
        event_category: 'engagement',
        event_label: 'login',
      });
      state.isFetching = false;
    },
    loginError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = loginSlice.actions;

const loginReducer = loginSlice.reducer;

export default loginReducer;
