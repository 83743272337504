import * as Yup from 'yup';

import { email as emailValidation } from 'utils/formsData';
import { NAME_VALIDATION, EMAIL_REGEXP } from 'utils/formsData/regexp';

export const errorDictionary = {
  ME0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  ME1: {
    fname: `This is a required field. Please only use characters A-Z a-z ' - , .`,
  },
  ME2: {
    lname: `This is a required field. Please only use characters A-Z a-z ' - , .`,
  },
  ME3: { email: 'You must use your work e-mail address.' },
  ME4: {
    toast: 'We are having technical difficulties. Can you try this again?',
  },
  ME5: {
    toast: 'We are having technical difficulties. Can you try this again?',
  },
  ME6: {
    toast: 'We are having technical difficulties. Can you try this again?',
  },
  ME7: {
    toast: 'We are having technical difficulties. Can you try this again?',
  },
  ME8: { subject: 'Your subject line must be between 10 and 200 characters.' },
  ME9: { message: 'Your message body must be between 25 and 1000 characters.' },
};

// Values for CotactUs fields
export const getTextFieldsData = (isAuthenticated) => ({
  fname: {
    label: 'First Name',
    disabled: isAuthenticated,
  },
  lname: {
    label: 'Last Name',
    disabled: isAuthenticated,
  },
  email: {
    type: 'email',
    label: 'Work E-Mail Address',
    disabled: isAuthenticated,
  },
});

export const selectData = {
  subject: {
    label: 'Subject',
    options: [
      {
        value: 'Support',
        label: 'Support',
      },
      { value: 'Question', label: 'Question' },
      { value: 'Comment or Feedback', label: 'Comment or Feedback' },
      { value: 'Chat with Evalueme Team', label: 'Chat with Evalueme Team' },
    ],
  },
};

// Initial values
export const getContactUsIntitalValues = (data) => {
  const { firstName, lastName, email } = data;

  const initialValues = {
    fname: firstName,
    lname: lastName,
    email,
    subject: '',
    message: '',
  };

  return initialValues;
};

// Schemas
export const requiredContactUsSchema = Yup.object({
  fname: Yup.string()
    .trim()
    .required('First Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  lname: Yup.string()
    .trim()
    .required('Last Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  email: emailValidation,
  subject: Yup.string().required('Please, choose one of the options'),
  message: Yup.string()
    .trim()
    .required('Message field should not be empty')
    .min(25, 'Message field should be at least 25 characters')
    .max(1000, 'Message field should not be more more than 1000 characters'),
});

export const optionalContactUsSchema = Yup.object({
  fname: Yup.string()
    .trim()
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  lname: Yup.string()
    .trim()
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  email: Yup.string()
    .notRequired()
    .matches(EMAIL_REGEXP, `Incorrect email format`),
  subject: Yup.string().required('Please, choose one of the options'),
  message: Yup.string()
    .trim()
    .required('Message field should not be empty')
    .min(25, 'Message field should be at least 25 characters')
    .max(1000, 'Message field should not be more more than 1000 characters'),
});
