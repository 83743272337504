import * as Yup from 'yup';

import { EMAIL_REGEXP, INTEGER } from 'utils/formsData/regexp';
import { date } from 'utils/formsData';

export const textFieldsData = {
  department: {
    label: 'Department',
  },
  title: {
    label: 'Your Title',
  },
  manager: {
    type: 'email',
    label: 'Manager’s Work E-mail Address',
  },
  salary: {
    type: 'text',
    label: 'Salary (Annual)',
  },
};

export const datePickersData = {
  dateStarted: {
    label: 'Started at Company',
  },
  dateStartedWithManager: {
    label: 'Started with Manager',
  },
  dateOfLastPromotion: {
    label: 'Last Promotion',
  },
  dateOfLastRaise: {
    label: 'Last Raise',
  },
};

export const errorDictionary = {
  IVPC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CVD2: {
    field: `Please select a year between 1940 and now.`,
  },
  UP0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CV0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CVPF1: {
    field: `Salary must be a positive whole number. No commas, periods & spaces allowed.`,
  },
  CVPF2: {
    field: `Salary must be a positive whole number. No commas, periods & spaces allowed.`,
  },
  CAPVME1: {
    field: `Your manager's e-mail address must have the same company domain.`,
  },
};

const minDate = new Date('1940-01-01T00:00:00');

export const validationSchema = Yup.object({
  department: Yup.string(),
  title: Yup.string(),
  manager: Yup.string().matches(EMAIL_REGEXP, `Incorrect email format`),
  salary: Yup.string()
    .matches(
      INTEGER,
      'Salary must be a positive whole number. No commas, periods & spaces allowed.',
    )
    .nullable(),
  dateStarted: date(minDate, '1940'),
  dateStartedWithManager: date(minDate, '1940'),
  dateOfLastPromotion: date(minDate, '1940'),
  dateOfLastRaise: date(minDate, '1940'),
  extraOne: Yup.bool().when(
    [
      'department',
      'title',
      'manager',
      'salary',
      'dateStarted',
      'dateStartedWithManager',
      'dateOfLastPromotion',
      'dateOfLastRaise',
    ],
    {
      is: (
        department,
        title,
        manager,
        salary,
        dateStarted,
        dateStartedWithManager,
        dateOfLastPromotion,
        dateOfLastRaise,
      ) =>
        !(
          Boolean(department) ||
          Boolean(title) ||
          Boolean(manager) ||
          Boolean(salary) ||
          Boolean(dateStarted) ||
          Boolean(dateStartedWithManager) ||
          Boolean(dateOfLastPromotion) ||
          Boolean(dateOfLastRaise)
        ),
      then: Yup.bool().required(),
      otherwise: Yup.bool(),
    },
  ),
});
