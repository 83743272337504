import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';

import {
  FormSubmitButton,
  FormFieldMap,
  FormSelect,
  SelectDatePicker,
} from 'components';
import { maxDateOfBirth } from 'utils/formsData/index';
import { actionTypes } from 'modules/Profile/redux/profileSlice';
import { getDemographicsData } from 'modules/Profile/redux/selectors';
import DisabilityCheckboxes from './DisabilityCheckboxes';

import { selectsData, validationSchema, errorDictionary } from './formsData';

import { Form, BirthWrapepr, FieldControl } from './DemographicsFormStyles';
import {
  ProfileFormWrapper,
  SubmitButtonControl,
  SubmitButtonWrapper,
} from '../commonStyles';

function DemographicsForm() {
  const dispatch = useDispatch();
  const demographicsData = useSelector(getDemographicsData);

  const saveUpdates = (data, { setErrors, setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.updateDemographicsRequest({
        data,
        errorDictionary,
        setErrors,
        setSubmitting,
        setStatus,
      }),
    );
  };

  return (
    <ProfileFormWrapper>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={demographicsData}
        validationSchema={validationSchema}
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormFieldMap
              horizontal
              addSelect
              selectData={selectsData}
              selectComponent={FormSelect}
            />
            <FieldControl>
              <DisabilityCheckboxes fieldName="hasDisability" />
              <BirthWrapepr>
                <Field
                  name="yearOfBirth"
                  label="Year of Birth"
                  views={['year']}
                  format="yyyy"
                  maxDate={maxDateOfBirth}
                  placeholder="Year"
                  component={SelectDatePicker}
                />
              </BirthWrapepr>
            </FieldControl>
            <SubmitButtonControl>
              <SubmitButtonWrapper>
                <FormSubmitButton
                  text="Save Updates"
                  isFetching={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </SubmitButtonWrapper>
            </SubmitButtonControl>
          </Form>
        )}
      </Formik>
    </ProfileFormWrapper>
  );
}

export default DemographicsForm;
