import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PublicRoute({
  component: Component,
  isAuthenticated,
  isPublic,
  ...rest
}) {
  const renderedComponent = (props) => {
    const { location } = props;

    return isAuthenticated !== true || isPublic ? (
      <Component {...props} />
    ) : (
      <Redirect push to={{ pathname: '/myWork', state: { from: location } }} />
    );
  };

  return <Route {...rest} render={renderedComponent} />;
}

export default PublicRoute;
