import * as Yup from 'yup';

import { LINKEDIN_REGEXP } from 'utils/formsData/regexp';

export const textFieldData = {
  name: 'linkedin',
  type: 'text',
  label: 'Your Linkedin Profile Link',
};

export const errorDictionary = {
  IVPC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CV0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CVLI1: {
    field: `Please use the correct LinkedIn profile URL, which includes linkedin.com/in/ followed by your profile ID.`,
  },
  CVLI0: {
    field: `Please use the correct LinkedIn profile URL, which includes linkedin.com/in/ followed by your profile ID.`,
  },
};

// Schemas
export const LinkedinFormSchema = Yup.object({
  linkedin: Yup.string()
    .required('Cannot send an empty value.')
    .min(1)
    .matches(
      LINKEDIN_REGEXP,
      `Please use the correct LinkedIn profile URL, which includes linkedin.com/in/ followed by your profile ID.`,
    ),
});
