import React, { useEffect } from 'react';

import AppRouter from 'routes';
import { MobileRoadblock } from 'components';

import useMobileRoadblock from 'hook/useMobileRoadblock';
import { useDispatch } from 'react-redux';
import { actionTypes } from 'modules/Dashboard/MyWork/redux/myWorkSlice';

function App() {
  const isMobile = useMobileRoadblock();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionTypes.initRequest());
  });

  return isMobile ? <MobileRoadblock /> : <AppRouter />;
}

export default App;
