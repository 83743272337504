import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';

import {
  FormFieldMap,
  FormSubmitButton,
  FormTextField,
  SelectDatePicker,
} from 'components';

import { getPositionInfoData } from 'modules/Profile/redux/selectors';
import { actionTypes } from 'modules/Profile/redux/profileSlice';
import {
  textFieldsData,
  validationSchema,
  datePickersData,
  errorDictionary,
} from './formsData';

import {
  ProfileFormWrapper,
  SubmitButtonControl,
  SubmitButtonWrapper,
} from '../commonStyles';
import {
  PositionInfoFormContainer,
  PickersControl,
  DatePickerContainer,
} from './PositionInfoFormStyles';

function PositionInfoForm() {
  const dispatch = useDispatch();
  const positionInfoValues = useSelector(getPositionInfoData);

  const saveUpdates = (data, { setErrors, setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.updatePositionInfoRequest({
        data,
        errorDictionary,
        setErrors,
        setSubmitting,
        setStatus,
      }),
    );
  };

  const datePickers = Object.keys(datePickersData).map((pickerName) => {
    return (
      <DatePickerContainer key={`date-picker-${pickerName}`}>
        <Field
          name={pickerName}
          label={datePickersData[pickerName].label}
          views={['year', 'date']}
          openTo="yaer"
          minDate={new Date('1940-01-01')}
          placeholder="Date"
          component={SelectDatePicker}
        />
      </DatePickerContainer>
    );
  });

  return (
    <ProfileFormWrapper>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={positionInfoValues}
        validationSchema={validationSchema}
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <PositionInfoFormContainer onSubmit={handleSubmit}>
            <FormFieldMap
              horizontal
              textFieldsData={textFieldsData}
              textField={FormTextField}
            />
            <PickersControl>{datePickers}</PickersControl>
            <SubmitButtonControl>
              <SubmitButtonWrapper>
                <FormSubmitButton
                  text="Save Updates"
                  isFetching={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </SubmitButtonWrapper>
            </SubmitButtonControl>
          </PositionInfoFormContainer>
        )}
      </Formik>
    </ProfileFormWrapper>
  );
}

export default PositionInfoForm;
