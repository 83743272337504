import styled from 'styled-components';

import { colors, typography, breakpoints } from 'styles';

export const P = styled.a`
  min-width: 88px;
  height: 26px;
  font-size: ${typography.size.XS};
  line-height: 1.44;
  text-decoration: none;
  font-family: ${typography.font.TEXT};
  color: ${colors.BRAND_COLOR};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.MD};
  }
`;
