import styled from 'styled-components';

import { breakpoints } from 'styles';

export const IntroWrapper = styled.div`
  margin-right: 0;

  @media (min-width: ${breakpoints.RG}) {
    margin-right: 20px;
  }
`;
