import React from 'react';
import { useDispatch } from 'react-redux';

import { FormSubmitButton, Button } from 'components';
import { closeModal } from 'modules/Modal/redux/modalSlice';
import { actionTypes } from 'modules/Dashboard/MyWork/redux/myWorkSlice';

import { ButtonsWrapper, ButtonControl } from './AddWorkItemModalStyles';

function Footer({ isValid, isSubmitting }) {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(actionTypes.setEditingMode(false));
    dispatch(closeModal());
  };

  return (
    <ButtonsWrapper>
      <ButtonControl>
        <Button text="Cancel" onClickAction={handleCancel} />
      </ButtonControl>
      <ButtonControl>
        <FormSubmitButton
          isFetching={isSubmitting}
          disabled={isSubmitting || !isValid}
          text="Post Work Item"
        />
      </ButtonControl>
    </ButtonsWrapper>
  );
}

export default Footer;
