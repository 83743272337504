import React from 'react';
import { FastField } from 'formik';

import { FormTextField, FormSelect } from 'components';
import { selectOptions } from './formsData';

import {
  FieldsContainer,
  FieldControl,
  FastTextArea,
} from './DisabilityFormStyles';

function DisabilityFormItem({ fieldName, selectName }) {
  return (
    <FieldsContainer>
      <FieldControl>
        <FastField
          name={selectName}
          label="Disability Type"
          selectOptions={selectOptions}
          component={FormSelect}
        />
      </FieldControl>
      <FieldControl>
        <FastTextArea
          name={fieldName}
          label="You can add more details about your disability"
          placeholder="Describe"
          component={FormTextField}
          multiline
          rowsMax="5"
        />
      </FieldControl>
    </FieldsContainer>
  );
}

export default DisabilityFormItem;
