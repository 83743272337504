import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { push as pushRoute } from 'connected-react-router';

import TopBarNavigatorButton from 'modules/Navigation/components/TopBarNavigatorButton';

import { routerPathnameSelector } from 'modules/Navigation/redux/selectors';

import { getTopBarNavigatorItems } from 'utils/navigation';

import {
  NavigationBar,
  TopBarNavigatorContainer,
  ToolBarContainer,
} from './TopBarNavigatorStyles';

function TopBarNavigator({
  topBarNavigatorButtonsData,
  push,
  isOneItem,
  oneItemData,
}) {
  const currentPathname = useSelector(routerPathnameSelector);

  const navigateToPage = (pushRouteName) => () => push(pushRouteName);

  const topBarNavigatorItems = getTopBarNavigatorItems(
    topBarNavigatorButtonsData,
    currentPathname,
  );

  const renderTopBarNavigatorButtons = () => {
    const topBarNavigatorButtons = topBarNavigatorItems.map(
      ({ buttonTitle, pushRouteName }) => (
        <TopBarNavigatorButton
          key={`top-bar-navigator-button-${buttonTitle}`}
          buttonTitle={buttonTitle}
          onClickAction={navigateToPage(pushRouteName)}
        />
      ),
    );

    return (
      <>
        {isOneItem ? (
          <TopBarNavigatorButton
            isOneItem
            oneItemText={oneItemData.text}
            buttonTitle={oneItemData.buttonTitle}
            onClickAction={navigateToPage(oneItemData.pushRouteName)}
          />
        ) : (
          topBarNavigatorButtons
        )}
      </>
    );
  };

  return (
    <TopBarNavigatorContainer>
      <NavigationBar>
        <ToolBarContainer>{renderTopBarNavigatorButtons()}</ToolBarContainer>
      </NavigationBar>
    </TopBarNavigatorContainer>
  );
}

TopBarNavigator.defaultProps = {
  topBarNavigatorButtonsData: [],
  isOneItem: PropTypes.false,
  oneItemData: {},
};

TopBarNavigator.propTypes = {
  topBarNavigatorButtonsData: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string.isRequired,
      pushRouteName: PropTypes.string.isRequired,
    }),
  ),
  push: PropTypes.func.isRequired,
  isOneItem: PropTypes.bool,
  oneItemData: PropTypes.shape({
    text: PropTypes.string,
    buttonTitle: PropTypes.string,
    pushRouteName: PropTypes.string,
  }),
};

export default connect(null, { push: pushRoute })(TopBarNavigator);
