import React from 'react';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { actionTypes as toastActionTypes } from 'modules/Toast/redux/toastSlice';

import { ButtonComponent } from 'components/Button/ButtonStyles';

import {
  ThankYouFormWrapper,
  ThankYouFormContainer,
  CheckIcon,
  Title,
  Description,
  ShareWrapper,
  ShareText,
  ButtonControl,
} from './ThankYouFormStyles';

function ThankYouForm() {
  const dispatch = useDispatch();

  const copyLink = () => {
    dispatch(toastActionTypes.showSuccessToast('Your link is copied.'));
  };

  return (
    <ThankYouFormWrapper>
      <ThankYouFormContainer>
        <CheckIcon />
        <Title>Thank You</Title>
        <Description>
          We will keep you updated on what you want to hear! Please share this
          with your friends and colleagues who might be interested.
        </Description>
        <ShareWrapper>
          <ShareText>Share By:</ShareText>
          <ButtonControl>
            <CopyToClipboard
              text="https://production.evalueme.ai"
              onCopy={copyLink}
            >
              <ButtonComponent>Copy Link</ButtonComponent>
            </CopyToClipboard>
          </ButtonControl>
        </ShareWrapper>
      </ThankYouFormContainer>
    </ThankYouFormWrapper>
  );
}

export default ThankYouForm;
