import styled from 'styled-components';
import { space, colors, breakpoints } from 'styles';

import { FormTitleText } from 'elements';

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row-reverse;
  margin: 16px 0;
  height: 100%;

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row-reverse;
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  max-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.MD}) {
    max-width: 100%;
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 472px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  margin-bottom: 16px;
  color: ${colors.MILESTONE_TAG};

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: ${space.LG};
  }
`;

export const FormControl = styled.div`
  width: 100%;
  margin: 0 ${space.XS};
`;

export const ButtonControl = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 36px;

  @media (min-width: ${breakpoints.MD}) {
    flex-direction: row-reverse;
    height: 50px;
  }
`;
