import styled from 'styled-components';

import { typography, colors } from 'styles';

export const ProfileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.XS};
  color: ${colors.WHITE};
  background-color: ${({ color }) => color || colors.BRAND_COLOR};
  border-radius: 20px;
  border: none;
`;
