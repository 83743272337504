import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { actionTypes } from 'modules/Profile/redux/profileSlice';

import { NavigationBarTabContainer } from './ProfileNavigationBarStyles';

function NavigationBarTab({ id, title, isActive, isPassed, isDisabled }) {
  const dispatch = useDispatch();
  const chooseTab = () => dispatch(actionTypes.setCurrentStep(id));

  return (
    <NavigationBarTabContainer
      isActive={isActive}
      isPassed={isPassed}
      onClick={chooseTab}
      isDisabled={isDisabled}
    >
      {title}
    </NavigationBarTabContainer>
  );
}

NavigationBarTab.defaultProps = {
  isDisabled: false,
};

NavigationBarTab.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isPassed: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default NavigationBarTab;
