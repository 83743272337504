import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FormFieldMap, FormTextField, FormSubmitButton } from 'components';
import { pageRoutes } from 'modules/Navigation/constants';
import { actionTypes } from 'modules/Auth/Login/redux/loginSlice';
import { loginFetchingSelector } from 'modules/Auth/Login/redux/selectors';

import {
  textFieldsData,
  loginFieldsInitialValues,
  loginSchema,
  errorDictionary,
} from './formsData';

import {
  FormContainer,
  Form,
  FormTitle,
  FormFieldWrapper,
  ButtonControl,
  NavLink,
} from './LoginFormStyle';

function LoginForm() {
  const dispatch = useDispatch();
  const {
    location: { state },
  } = useHistory();

  const isFetching = useSelector(loginFetchingSelector);

  const handleSubmitButton = (data, { setErrors }) => {
    const { loginRequest } = actionTypes;

    const redirectPath = state?.from || pageRoutes.MY_WORK;
    dispatch(loginRequest({ data, errorDictionary, setErrors, redirectPath }));

    gtag('event', 'login', {
      event_category: 'engagement',
      event_label: 'login',
    });
  };

  return (
    <FormContainer>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={loginFieldsInitialValues}
        validationSchema={loginSchema}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>Welcome Back</FormTitle>
            <FormFieldWrapper>
              <FormFieldMap
                vertical
                textField={FormTextField}
                textFieldsData={textFieldsData}
              />
            </FormFieldWrapper>
            <ButtonControl>
              <FormSubmitButton
                text="Sign In"
                disabled={isFetching || !isValid}
                isFetching={isFetching}
              />
            </ButtonControl>
            <NavLink to={pageRoutes.CONTACT_US} exact>
              Contact us
            </NavLink>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default LoginForm;
