import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import { FormErrorText } from 'elements';

import {
  SelectWrapper,
  selectStyles,
  SelectLabel,
  Selector,
  ErrorWrapper,
} from './FormSelectStyle';

function FormSelect({
  field,
  form: { status, setFieldValue, errors, touched, setFieldTouched },
  label,
  isSearchable,
  selectOptions,
}) {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const fieldStatus = getIn(status, field.name);

  const isError = !!error && !!touch;

  const onChangeHandler = (data) => setFieldValue(field.name, data.value);

  const customTouchedHandler = () => setFieldTouched(field.name, true);

  return (
    <SelectWrapper>
      <SelectLabel>{label}</SelectLabel>
      <Selector
        {...field}
        value={
          selectOptions.length && field.value
            ? selectOptions.find((option) => option.value === field.value)
            : ''
        }
        options={selectOptions}
        placeholder="Select"
        styles={selectStyles}
        isError={isError}
        success={fieldStatus}
        isSearchable={isSearchable}
        onChange={onChangeHandler}
        blurInputOnSelect={false}
        onBlur={customTouchedHandler}
      />
      <ErrorWrapper>
        {error && touch && <FormErrorText>{error}</FormErrorText>}
      </ErrorWrapper>
    </SelectWrapper>
  );
}

FormSelect.defaultProps = {
  isSearchable: true,
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.array.isRequired,
  isSearchable: PropTypes.bool,
};

export default FormSelect;
