import * as Yup from 'yup';

import { password, rpassword } from 'utils/formsData';

export const textFieldsData = {
  password: {
    type: 'password',
    label: 'Create Password',
  },
  rpassword: {
    type: 'password',
    label: 'Re-enter Password',
  },
};

export const errorDictionary = {
  RESET1: {
    toast: `If user exists, we have sent you an e-mail with a password reset link. If you don't receive it, please email us at help@evalueme.ai.`,
  },
  RESET2: {
    toast: `If user exists, we have sent you an e-mail with a password reset link. If you don't receive it, please email us at help@evalueme.ai.`,
  },
  RESETPASS1: {
    toast: `If user exists, we have sent you an e-mail with a password reset link. If you don't receive it, please email us at help@evalueme.ai.`,
  },
  RESETPASS2: {
    rpassword: `Your Create Password link expired. <br>Please <a href="/contactUs" target="_blank" class="ContactUs-Link">Contact Us</a> to fix this.`,
  },
  RESETPASS3: {
    toast: `Your Reset Password link expired. If you requested Password Reset multiple times, please use the most recent e-mail link.`,
  },
  RESETPASS0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  RESETPASS4: {
    toast: `You have already set your password. Please log in or email us at help@evalueme.ai for additional assistance.`,
  },
};

// Schemas
export const createPasswordAuthSchema = Yup.object({
  password,
  rpassword,
});

// Initial values
export const createPasswordAuthInitialValues = {
  password: '',
  rpassword: '',
};
