import React from 'react';
import { useSelector } from 'react-redux';

import { getCollaborators } from 'modules/Dashboard/MyWork/redux/selectors';
import InviteField from './InviteField';
import { inviteFieldsData } from './formsData';

import { InviteFieldsContainer } from './AddWorkItemModalStyles';

function InviteFieldsSection() {
  const options = useSelector(getCollaborators);

  return (
    <InviteFieldsContainer>
      {inviteFieldsData.map(
        ({ name, label, placeholder, noOptionsMessage }) => (
          <InviteField
            key={`invite-field-${name}`}
            name={name}
            label={label}
            placeholder={placeholder}
            options={options}
            noOptionsMessage={noOptionsMessage}
          />
        ),
      )}
    </InviteFieldsContainer>
  );
}

export default InviteFieldsSection;
