import { put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { requestWrapper as request } from 'utils';

import api from 'services';

import { pageRoutes } from 'modules/Navigation/constants';

import { actionTypes } from './industryTypeSlice';

function* industryWaitList({ payload: { data, errorDictionary, setErrors } }) {
  const { response, error } = yield request(
    api.industryWaitList,
    data,
    errorDictionary,
    setErrors,
  );

  if (response) {
    yield put(actionTypes.industryWaitListSuccess());
    yield put(push(pageRoutes.WAIT_LIST_THANKS));
  } else {
    if (error?.status === 302) {
      yield put(push(pageRoutes.HOME));
    }

    yield put(actionTypes.industryWaitListError());
  }
}

export default function* industrySaga() {
  yield all([
    takeLatest(actionTypes.industryWaitListRequest, industryWaitList),
  ]);
}
