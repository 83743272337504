import {
  JoinPage,
  IndustryTypePage,
  CreatePasswordPage,
  CreateAccountPage,
  CreateCompanyPage,
  LoginPage,
  ThankYouPage,
  CheckEmailPage,
  MyWork,
  ContactUs,
  ColleaguesWork,
  Profile,
  MyFeedback,
  ColleaguesFeedback,
  Terms,
  Privacy,
} from 'pages';

export default [
  {
    path: '/',
    component: JoinPage,
    exact: true,
  },
  {
    path: '/waitlist',
    component: IndustryTypePage,
    isPublic: true,
  },
  {
    path: '/createAccount',
    component: CreateAccountPage,
    isPublic: true,
  },
  {
    path: '/createPassword',
    component: CreatePasswordPage,
    isPublic: true,
  },
  {
    path: '/createCompany',
    component: CreateCompanyPage,
    isPublic: true,
  },
  {
    path: '/login',
    component: LoginPage,
    isPublic: false,
  },
  {
    path: '/waitlistThanks',
    component: ThankYouPage,
    isPublic: true,
  },
  {
    path: '/checkEmail',
    component: CheckEmailPage,
    isPublic: true,
  },
  {
    path: '/contactUs',
    component: ContactUs,
    isPublic: true,
  },
  {
    path: '/myWork',
    component: MyWork,
    isPrivate: true,
  },
  {
    path: '/colleaguesWork',
    component: ColleaguesWork,
    isPrivate: true,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
  },
  {
    path: '/myFeedback/:id',
    component: MyFeedback,
    isPrivate: true,
  },
  {
    path: '/colleaguesFeedback/:id',
    component: ColleaguesFeedback,
    isPrivate: true,
  },
  {
    path: '/terms',
    component: Terms,
    isPublic: true,
  },
  {
    path: '/privacy',
    component: Privacy,
    isPublic: true,
  },
];
