import styled from 'styled-components';

import { LogoSmall, LogoMedium, LogoLarge } from 'assets';
import { CompanyTitle } from 'elements';
import { typography, colors, space, breakpoints } from 'styles';

export const CheckEmailSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;

  @media (mib-width: ${breakpoints.MD}) {
    padding: 0;
  }
`;

export const CheckEmailSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.WHITE};

  @media (min-width: ${breakpoints.MD}) {
    width: 650px;
  }
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.MD};
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.LG};
  }
`;

export const Description = styled.p`
  margin: ${space.XS} 0 0;
  padding: 0;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    margin-top: 16px;
    font-size: ${typography.size.MD};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: ${space.SM};
  }
`;

export const Logo = styled.img.attrs({
  src: LogoLarge,
  srcSet: `${LogoSmall} 96w, ${LogoMedium} 192w, ${LogoLarge} 288w`,
  sizes: `
    (max-width: ${breakpoints.SM}) 35px,
    (max-width: ${breakpoints.LG}) 35px,
    35px
  `,
})`
  height: 35px;
`;

export const LogoTitle = styled(CompanyTitle)`
  margin-left: 5px;
`;

export const Email = styled.span`
  margin: 0;
  padding: 0;
  color: ${colors.BRAND_COLOR};

  &:hover {
    cursor: pointer;
  }
`;
