import styled from 'styled-components';

import { breakpoints, colors, space, typography } from 'styles';

import { Check } from 'assets';

export const ThankYouFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  border: solid 1px ${colors.OUTLINES};
  box-shadow: 0 2px 24px 0 rgba(26, 35, 53, 0.5);
  border-radius: 4px;

  @media (min-width: ${breakpoints.RG}) {
    width: min-content;
  }
`;

export const ThankYouFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${space.MD} 8px;
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.SM}) {
    padding: 85px ${space.LG} ${space.LG} ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    width: 470px;
  }
`;

export const CheckIcon = styled(Check)`
  margin-bottom: ${space.XS};
`;

export const Title = styled.p`
  margin: 0 0 5px;
  padding: 0;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.MD};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.LG};
  }
`;

export const Description = styled.p`
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  text-align: center;

  @media (min-width: ${breakpoints.SM}) {
    width: 325px;
  }

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.SM};
    color: ${colors.PRIMARY};
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: ${breakpoints.MD}) {
    margin-top: 65px;
  }
`;

export const ShareText = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.SM};
  color: ${colors.SECONDARY_TEXT};
`;

export const ButtonControl = styled.div`
  width: 100%;
  height: 36px;
  margin-top: 25px;

  @media (min-width: ${breakpoints.SM}) {
    width: 390px;
    height: 50px;
  }
`;
