import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { push } from 'connected-react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import LoadingState from 'modules/Dashboard/components/LoadingState';
import {
  getPersonInitials,
  getPersonFullName,
} from 'modules/Profile/redux/selectors';
import { SideBar } from 'modules/Navigation';
import UpfulEditor from '@upfulai/ws_upful_editor_library';

import {
  getAuthenticatedToken,
  getPersonId,
  getCompanyId,
} from 'modules/Auth/AuthProvider/redux/selectors';
import Header from '../components/Header';
import FeedbackLeftPanel from '../components/FeedbackTopPanel';
import {
  FeedbackPageWrapper,
  FeedbackContentWrapper,
  FeedbackContent,
} from '../components/SharedStyledComponents';
import { actionTypes } from './redux/colleaguesFeedbackSlice';
import { getActiveItem } from './redux/selectors';

function ColleaguesFeedback({ id, push: navigate }) {
  const dispatch = useDispatch();
  const workItem = useSelector(getActiveItem);
  const revieweeInitials = useSelector(getPersonInitials);
  const { firstName, lastName } = useSelector(getPersonFullName);
  const { work } = workItem;
  const navigateToPage = () => navigate('/colleaguesWork');
  const getVisibleName = (fn, ln) => `${fn} ${ln.charAt(0)}.`;

  const token = useSelector(getAuthenticatedToken);
  const personId = useSelector(getPersonId);
  const companyId = useSelector(getCompanyId);
  const [, setTimeSince] = useState(0);

  const handleKeyDown = useCallback(() => {
    setTimeSince(0);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    const timer = setInterval(() => {
      setTimeSince((time) => {
        if (time === 300) {
          clearInterval(timer);
          dispatch(push('/colleaguesWork'));
        }
        return time + 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch, handleKeyDown]);

  useEffect(() => {
    if (!work || work.id !== id) {
      dispatch(actionTypes.getAllWorkItemRequest(id));
    }
  }, [companyId, dispatch, id, navigate, personId, token, work]);

  return (
    <FeedbackPageWrapper>
      <SideBar isHidden />
      {work && work.id === id ? (
        <>
          <Header
            title={work.title}
            ownerInitials={revieweeInitials}
            firstName={firstName}
            lastName={lastName}
            creatorUserName={getVisibleName(
              work.creator_first_name,
              work.creator_last_name,
            )}
            handleBackClick={navigateToPage}
          />
          <FeedbackContentWrapper>
            <FeedbackLeftPanel
              description={work.description}
              createdDate={work.created_on}
              updatedDate={work.updated_on}
              isShow={false}
            />
            <FeedbackContent id={`wi-feedback-${id || v4()}`}>
              <UpfulEditor
                reviewer_id={personId}
                company_id={companyId}
                reviewee_email={work.creator}
                work_item_id={id}
                jwt={token}
              />
            </FeedbackContent>
          </FeedbackContentWrapper>
        </>
      ) : (
        <LoadingState />
      )}
    </FeedbackPageWrapper>
  );
}

ColleaguesFeedback.propTypes = {
  id: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(null, { push })(ColleaguesFeedback);
