import DateFnsAdapter from '@date-io/date-fns';

export const personInfoResponseNormalizer = (data) => {
  const validDateOfBirth = data.year_of_birth
    ? new DateFnsAdapter().setYear(new Date(), data.year_of_birth)
    : null;

  const checkDisability = (hasDisability) =>
    typeof hasDisability === 'boolean' ? hasDisability : null;

  const convertDemographicsValue = (value) =>
    typeof value === 'number' ? String(value) : null;

  const checkStringValue = (value) => (value === undefined ? '' : value);

  const validatePositionDate = (date) =>
    date === undefined ? null : new Date(date);

  return {
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    linkedin: data.linkedin || '',
    accountOccupancy: data.percent,
    demographics: {
      gender: convertDemographicsValue(data.gender),
      ethnicity: convertDemographicsValue(data.ethnicity),
      religion: convertDemographicsValue(data.religion),
      lgbtqStatus: convertDemographicsValue(data.lgbtq_status),
      hasDisability:
        data.has_disability !== null || data.has_disability !== undefined
          ? checkDisability(data.has_disability)
          : null,
      yearOfBirth: validDateOfBirth,
    },
    disability:
      data.disability.length !== 0
        ? data.disability.map((disabilityData) => ({
            disabilityType: disabilityData.disability_type,
            description: disabilityData.description,
            dateOccurred: disabilityData.date_occurred,
            dateReported: disabilityData.date_reported,
          }))
        : [],
    positionInfo: {
      department: checkStringValue(data.department),
      title: checkStringValue(data.title),
      manager: checkStringValue(data.manager),
      salary: checkStringValue(data.salary),
      dateStarted: validatePositionDate(data.date_started),
      dateStartedWithManager: validatePositionDate(
        data.date_started_with_manager,
      ),
      dateOfLastPromotion: validatePositionDate(data.date_of_last_promotion),
      dateOfLastRaise: validatePositionDate(data.date_of_last_raise),
    },
  };
};

export const cleanObject = (object) => {
  Object.keys(object).forEach(
    (key) => object[key] === null && delete object[key],
  );
};

export const updatePersonRequestNormalizer = (data, oldData, personId) => {
  const deltas = [];

  cleanObject(data);

  Object.keys(data).map((fieldName) =>
    deltas.push({
      field_name: fieldName,
      old_value: oldData[fieldName],
      new_value: data[fieldName],
    }),
  );

  return {
    person_id: personId,
    change: {
      date_of_change: `${new Date().toISOString().split('.')[0]}Z`,
      by: personId,
      deltas,
    },
  };
};

export const updateBasicInfoDataNormalizer = (data, oldData) => ({
  newData: {
    first_name: data.firstName.trim(),
    last_name: data.lastName.trim(),
  },
  old: {
    first_name: oldData.firstName,
    last_name: oldData.lastName,
  },
});

export const updateLinkedinNormalizer = (data, oldData) => ({
  newData: {
    linkedin: data.linkedin,
  },
  old: {
    linkedin: oldData.linkedin === '' ? null : oldData.linkedin,
  },
});

export const updateDemographicsDataNormalizer = (data, oldData) => {
  const convertToNumber = (value) => (value !== null ? Number(value) : null);

  const mapFieldsData = (dataObject) => ({
    gender: convertToNumber(dataObject.gender),
    ethnicity: convertToNumber(dataObject.ethnicity),
    religion: convertToNumber(dataObject.religion),
    lgbtq_status: convertToNumber(dataObject.lgbtqStatus),
    has_disability:
      dataObject.hasDisability !== null ? dataObject.hasDisability : null,
    year_of_birth:
      dataObject.yearOfBirth !== null
        ? dataObject.yearOfBirth.getFullYear()
        : null,
  });

  const newValues = mapFieldsData(data);
  const oldValues = mapFieldsData(oldData);

  return { newValues, oldValues };
};

export const updateDisabilityRequestNormalizer = (data) => {
  const today = `${new Date().toISOString().split('.')[0]}Z`;

  return data.disabilityTypes.map(({ disabilityType, description }, index) => ({
    index,
    disability_type: disabilityType,
    description: description.trim(),
    date_occurred: today,
    date_reported: today,
  }));
};

export const updatePersonInfoNormalizer = (data, oldData) => {
  const deleteMilliseconds = (date) =>
    date !== null ? `${new Date(date).toISOString().split('.')[0]}Z` : null;

  const changeOldStringValue = (value) => (value === '' ? null : value);

  const mapFieldsData = (dataObject) => ({
    department: dataObject.department,
    title: dataObject.title,
    manager: dataObject.manager,
    salary: dataObject.salary,
    date_started: deleteMilliseconds(dataObject.dateStarted),
    date_started_with_manager: deleteMilliseconds(
      dataObject.dateStartedWithManager,
    ),
    date_of_last_promotion: deleteMilliseconds(dataObject.dateOfLastPromotion),
    date_of_last_raise: deleteMilliseconds(dataObject.dateOfLastRaise),
  });

  const newValues = mapFieldsData(data);
  const oldValues = {
    department: changeOldStringValue(oldData.department),
    title: changeOldStringValue(oldData.title),
    manager: changeOldStringValue(oldData.manager),
    salary: changeOldStringValue(oldData.salary),
    date_started: deleteMilliseconds(oldData.dateStarted),
    date_started_with_manager: deleteMilliseconds(
      oldData.dateStartedWithManager,
    ),
    date_of_last_promotion: deleteMilliseconds(oldData.dateOfLastPromotion),
    date_of_last_raise: deleteMilliseconds(oldData.dateOfLastRaise),
  };

  Object.keys(newValues).forEach(
    (key) => newValues[key] === '' && delete newValues[key],
  );

  return { newValues, oldValues };
};
