import React, { useEffect } from 'react';
import { TopBarNavigator } from 'modules/Navigation';
import { LoginForm } from 'modules/Auth';

import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from 'elements';

import { IntroSection } from 'components';

import { topBarNavigatorButtonsData } from 'modules/Navigation/constants';

function Login() {
  useEffect(() => {
    // #GTAG;
    gtag('event', 'page_view', {
      page_title: 'Login',
      page_path: window.location.origin + window.location.pathname,
    });
  });
  return (
    <AuthPageWrapper>
      <NavigationBarContainer>
        <TopBarNavigator
          topBarNavigatorButtonsData={topBarNavigatorButtonsData}
        />
      </NavigationBarContainer>
      <PageContainer>
        <IntroSection description="Evalueme coaches employees to provide more meaningful, actionable, and unbiased performance reviews using AI and behavioral science." />
        <LoginForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default Login;
