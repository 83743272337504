import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getSearchDataSelector } from 'modules/Navigation/redux/selectors';

import { pageRoutes } from 'modules/Navigation/constants';
import { requestWrapper as request } from 'utils';
import api from 'services';

import { setPasswordRequestNormalizer } from './normalizer';
import { actionTypes } from './createPasswordSlice';

function* createPassword({ payload: { data, errorDictionary, setErrors } }) {
  const searchData = yield select(getSearchDataSelector);

  const createPasswordData = setPasswordRequestNormalizer({
    ...data,
    ...searchData,
  });

  const { response } = yield request(
    api.createPassword,
    createPasswordData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    yield put(actionTypes.createPasswordSuccess());
    yield put(push(pageRoutes.LOG_IN));
  } else {
    yield put(actionTypes.createPasswordError());
  }
}

export default function* createPasswordSaga() {
  yield all([takeLatest(actionTypes.createPasswordRequest, createPassword)]);
}
