import React, { useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { pageRoutes } from 'modules/Navigation/constants';

import { getIsAccountFull } from 'modules/Profile/redux/selectors';
import { actionTypes as authActionTypes } from 'modules/Auth/AuthProvider/redux/authProviderSlice';

import {
  ProfileMenuWrapper,
  ProfileMenuContainer,
  ProfileIconButton,
  UserMenu,
  UserMenuHeader,
  UserMenuHeaderDescription,
  UserMenuItemList,
  UserMenuItem,
  ArrowIcon,
  MenuLink,
  UserMenuHeaderTitle,
} from './ProfileMenuStyles';

function ProfileMenu({ iconText, firstName, lastName, push: navigate }) {
  const dispatch = useDispatch();
  const isAccountFull = useSelector(getIsAccountFull);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfileClick = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const logout = () => {
    dispatch(authActionTypes.logoutRequest());
  };

  const navigateToProfile = () => navigate(pageRoutes.PROFILE);

  return (
    <ProfileMenuWrapper>
      <ProfileMenuContainer>
        <ProfileIconButton
          aria-haspopup="true"
          aria-controls="menu"
          onClick={handleProfileClick}
        >
          {iconText}
        </ProfileIconButton>
        <UserMenu
          id="menu"
          keepMounted
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <UserMenuHeader>
            <UserMenuHeaderTitle>{firstName}</UserMenuHeaderTitle>
            <UserMenuHeaderTitle>{lastName}</UserMenuHeaderTitle>
            <UserMenuHeaderDescription onClick={navigateToProfile}>
              {isAccountFull ? `View` : `Complete`} Profile
            </UserMenuHeaderDescription>
          </UserMenuHeader>
          <UserMenuItemList>
            <UserMenuItem>
              <MenuLink href="/contactUs" target="_blank">
                Support
                <ArrowIcon />
              </MenuLink>
            </UserMenuItem>
            <UserMenuItem onClick={logout}>
              Logout
              <ArrowIcon />
            </UserMenuItem>
          </UserMenuItemList>
        </UserMenu>
      </ProfileMenuContainer>
    </ProfileMenuWrapper>
  );
}

ProfileMenu.propTypes = {
  iconText: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default connect(null, { push })(ProfileMenu);
