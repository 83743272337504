import styled from 'styled-components';
import { colors } from 'styles';

export const FeedbackPageWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: -webkit-fill-available;
  background-color: ${colors.PRIMARY_BG};

  &>div>div.MuiPaper-root {
    position fixed;
  }
`;

export const FeedbackContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 120px 80px 50px 160px;
  height: 100%;
  width: 100%;
`;

export const FeedbackContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
