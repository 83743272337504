import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { FormTextField, FormSubmitButton, FormFieldMap } from 'components';

import { getBasicProfileInfo } from 'modules/Profile/redux/selectors';
import { actionTypes } from 'modules/Profile/redux/profileSlice';

import {
  textFieldsData,
  basicInfoFormSchema,
  errorDictionary,
} from './formsData';

import {
  ProfileFormWrapper,
  SubmitButtonControl,
  SubmitButtonWrapper,
} from '../commonStyles';

function BasicInfoForm() {
  const dispatch = useDispatch();

  const profileBasicInfoValues = useSelector(getBasicProfileInfo);

  const saveUpdates = (data, { setErrors, setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.updateBasicInfoRequest({
        data,
        errorDictionary,
        setErrors,
        setSubmitting,
        setStatus,
      }),
    );
  };

  return (
    <ProfileFormWrapper>
      <Formik
        enableReinitialize
        initialValues={profileBasicInfoValues}
        validationSchema={basicInfoFormSchema}
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormFieldMap
              horizontal
              textFieldsData={textFieldsData}
              textField={FormTextField}
            />
            <SubmitButtonControl>
              <SubmitButtonWrapper>
                <FormSubmitButton
                  text="Save Updates"
                  isFetching={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </SubmitButtonWrapper>
            </SubmitButtonControl>
          </Form>
        )}
      </Formik>
    </ProfileFormWrapper>
  );
}

export default BasicInfoForm;
