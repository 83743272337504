import styled from 'styled-components';

import { space, colors, breakpoints, typography } from 'styles';

import { FormTitleText } from 'elements';

import { PhoneIcon as Phone } from 'assets';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  height: min-content;
  width: 100%;
  max-width: max-content;
  margin: 30px 0 20px 0;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: end;
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.LG}) {
    margin: 0 20px 0 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
  padding: ${space.XS} 16px;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  .MuiInputBase-root {
    padding: ${space.XXS} 0 0 0;
  }

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }

  @media (min-width: ${breakpoints.MD}) {
    width: 720px;
    padding: ${space.LG};

    .MuiInputBase-root {
      padding: 0;
    }
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 430px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  align-self: center;
  margin-bottom: 22px;
  color: ${colors.MILESTONE_TAG};
`;

export const FormDescription = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
  font-family: ${typography.font.TEXT};
  color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: 49px;
  }
`;

export const PhoneIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin-bottom: ${space.XS};
`;

export const PhoneIcon = styled(Phone)``;

export const ButtonControl = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: ${space.XS};

  @media (min-width: ${breakpoints.MD}) {
    margin-top: ${space.LG};
  }
`;

export const ButtonContainer = styled.div`
  height: 50px;
  flex: 0 45%;
`;
