import styled from 'styled-components';
import { FastField } from 'formik';

import { AddIcon } from 'assets';
import { ProfileFormWrapper } from 'modules/Profile/components/commonStyles';
import { breakpoints, colors, space, typography } from 'styles';
import { SubmitButtonControl } from '../commonStyles';

export const FormWrapper = styled(ProfileFormWrapper)`
  display: flex;
  flex-direction: column;
  min-height: 450px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DisabilityControl = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  > div:first-child {
    @media (min-width: 1170px) {
      padding-right: 20px;
      box-sizing: border-box;
    }

    @media (min-width: ${breakpoints.XL}) {
      box-sizing: content-box;
    }
  }

  @media (min-width: 1170px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const FieldControl = styled.div`
  display: flex;
  flex: 0 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5px;

  :last-child {
    margin-right: 0;
  }

  @media (min-width: 1170px) {
    max-width: 390px;
  }
`;

export const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  cursor: pointer;

  &:active {
    opacity: 0.75;
  }
`;

export const AddItemIcon = styled(AddIcon)``;

export const AddItemText = styled.span`
  margin-left: ${space.XXS};
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.SM};
  color: ${colors.BRAND_COLOR};
`;

export const ButtonControl = styled(SubmitButtonControl)`
  margin-top: ${space.LG};
`;

export const FastTextArea = styled(FastField)`
  .MuiInputBase-root {
    min-height: 50px;
    padding-bottom: 0;
  }
`;
