import React from 'react';
import { useDispatch } from 'react-redux';

import { actionTypes as toastActionTypes } from 'modules/Toast/redux/toastSlice';

import {
  CheckEmailSectionWrapper,
  CheckEmailSectionContainer,
  LogoWrapper,
  Logo,
  LogoTitle,
  Title,
  Description,
  Email,
} from './CheckEmailSectionStyles';

function CheckEmailSection() {
  const dispatch = useDispatch();

  const copyEmail = (event) => {
    const data = event.currentTarget.textContent;

    navigator.clipboard.writeText(data);
    dispatch(toastActionTypes.showSuccessToast('Email is copied.'));
  };

  return (
    <CheckEmailSectionWrapper>
      <CheckEmailSectionContainer>
        <LogoWrapper>
          <Logo />
          <LogoTitle>evalueme.ai</LogoTitle>
        </LogoWrapper>
        <Title>
          You should receive an e-mail from us with a link to verify your work
          e-mail address and to set your password.
        </Title>
        <Description>
          If you don’t receive the e-mail, please contact us at{' '}
          <Email onClick={copyEmail}>
            missingAccountCreationEmail@evalueme.ai
          </Email>
        </Description>
      </CheckEmailSectionContainer>
    </CheckEmailSectionWrapper>
  );
}

export default CheckEmailSection;
