import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { pageRoutes } from 'modules/Navigation/constants';
import { actionTypes as toastActionTypes } from 'modules/Toast/redux/toastSlice';
import { actionTypes as actionTypesDashboard } from 'modules/Dashboard/MyWork/redux/myWorkSlice';
import { openModal, closeModal } from 'modules/Modal/redux/modalSlice';
import { MODAL_TYPES } from 'modules/Modal/constants';

import { requestWrapper as request } from 'utils';
import api from 'services';
import {
  normalizeCollaboratorsResponse,
  normalizeUpdateWorkItemRequest,
  normilizeWorkItemError,
} from '../../../Dashboard/MyWork/redux/normalizer';

import { actionTypes } from './myFeedbackSlice';
import { getActiveItemID } from './selectors';

function* openAddTeamModal() {
  yield put(openModal({ modalType: MODAL_TYPES.ADD_TEAM_MODAL }));

  const { response } = yield request(api.getCompanyInfo);

  if (response) {
    const collaboratorsOptions = normalizeCollaboratorsResponse(
      response.peopleInfo,
    );

    yield put(actionTypesDashboard.setCollaborators(collaboratorsOptions));
  }
}

function* getMyWorkItem({ payload: id }) {
  const { response } = yield request(api.getMyWorkItem, id);

  if (response) {
    yield put(actionTypes.getMyWorkItemSuccess(response));
  } else {
    yield put(
      toastActionTypes.showErrorToast(
        'You do not have access to this work item.',
      ),
    ); // get rid with new error type
    yield put(push(pageRoutes.MY_WORK));
    yield put(actionTypes.getMyWorkItemError());
  }
}

function* updateWorkItem({
  payload: { data, errorDictionary, setSubmitting, setStatus },
}) {
  const requestData = normalizeUpdateWorkItemRequest(data);
  const id = yield select(getActiveItemID);

  const { response, error } = yield request(
    api.postWorkItem,
    {
      data: requestData,
      id,
    },
    errorDictionary,
  );

  if (response) {
    yield put(
      toastActionTypes.showSuccessToast(
        'We have updated your tagged Collaborators and Stakeholders.',
      ),
    );
    yield put(actionTypes.getMyWorkItemRequest(id));
    yield put(closeModal());
    setSubmitting(false);
    yield put(actionTypes.updateWorkItemSuccess());
  } else {
    const invalidEmails = normilizeWorkItemError(error);
    setStatus(invalidEmails);

    setSubmitting(false);
  }
}

function* getMyWorkItemFeedback({ payload: { id, email } }) {
  const { response } = yield request(api.getMyWorkItemFeedback, { id, email });

  if (response) {
    yield put(actionTypes.getMyWorkItemRequest(id));
  }
}

export default function* myFeedbackSaga() {
  yield all([
    takeLatest(actionTypes.openAddTeamModal, openAddTeamModal),
    takeLatest(actionTypes.updateWorkItemRequest, updateWorkItem),
    takeLatest(actionTypes.getMyWorkItemRequest, getMyWorkItem),
    takeLatest(actionTypes.getMyWorkItemFeedbackRequest, getMyWorkItemFeedback),
  ]);
}
