/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FieldArray } from 'formik';

import { FormSubmitButton } from 'components';
import { getDisabilities } from 'modules/Profile/redux/selectors';
import { actionTypes } from 'modules/Profile/redux/profileSlice';
import DisabilityFormItem from './DisabilityFormItem';
import { initialValues, disabilityItemValue, schema } from './formsData';

import { SubmitButtonWrapper } from '../commonStyles';
import {
  FormWrapper,
  Form,
  DisabilityControl,
  AddItemContainer,
  AddItemIcon,
  AddItemText,
  ButtonControl,
} from './DisabilityFormStyles';

function DisabilityForm() {
  const dispatch = useDispatch();
  const disability = useSelector(getDisabilities);
  const disabilityInitialValues =
    disability.length === 0 ? initialValues : { disabilityTypes: disability };

  const saveUpdates = (data, { setSubmitting }) => {
    dispatch(actionTypes.updateDisabilityRequest({ data, setSubmitting }));
  };

  return (
    <FormWrapper>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={disabilityInitialValues}
        validationSchema={schema}
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isValid, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="disabilityTypes"
              render={(disabilityArray) => (
                <DisabilityControl>
                  {values.disabilityTypes.map((_, index) => (
                    <DisabilityFormItem
                      key={`disability-form-item-${index}`}
                      selectName={`disabilityTypes[${index}].disabilityType`}
                      fieldName={`disabilityTypes[${index}].description`}
                    />
                  ))}
                  <AddItemContainer
                    onClick={() => disabilityArray.push(disabilityItemValue)}
                  >
                    <AddItemIcon />
                    <AddItemText>Add Another Disability</AddItemText>
                  </AddItemContainer>
                </DisabilityControl>
              )}
            />
            <ButtonControl>
              <SubmitButtonWrapper>
                <FormSubmitButton
                  text="Save Updates"
                  isFetching={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </SubmitButtonWrapper>
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
}

export default DisabilityForm;
