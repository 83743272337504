import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { actionTypes } from 'modules/Feedback/MyFeedback/redux/myFeedbackSlice';

import { getTeamInitialValues } from 'modules/Feedback/utils/getTeamInitialValues';

import InfoHeader from './InfoHeader';
import InviteFieldsSection from './InviteFieldsSection';
import Footer from './Footer';

import { errorDictionary } from './formsData';

import { AddWorkItemModalWrapper, Form } from './AddTeamModalStyles';
import { getActiveItem } from '../../redux/selectors';

function AddWorkItemModal() {
  const dispatch = useDispatch();
  const { work } = useSelector(getActiveItem);

  const updateWorkItem = (data, { setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.updateWorkItemRequest({
        data,
        errorDictionary,
        setSubmitting,
        setStatus,
      }),
    );
  };

  return (
    <AddWorkItemModalWrapper>
      <Formik
        validateOnMount
        initialValues={getTeamInitialValues(work)}
        onSubmit={updateWorkItem}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <InfoHeader />
            <InviteFieldsSection />
            <Footer isValid={isValid} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </AddWorkItemModalWrapper>
  );
}

export default AddWorkItemModal;
