import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { EMAIL_REGEXP } from 'utils/formsData/regexp';

import InviteOption from './InviteOption';
import InviteInput from './InviteInput';
import InviteNoOptionMessage from './InviteNoOptionMessage';

import { MultiSelectContainer, multiSelectStyles } from './MultiSelectStyles';

function MultiSelect({
  field,
  form: { setFieldValue, status },
  options,
  placeholder,
  noOptionsMessage,
  maxMenuHeight,
  menuWidth,
  fieldType,
  ...props
}) {
  const selectRef = useRef(null);
  const [rigthOffset, setRightOffset] = useState(0);
  const [menuBias, changeMenuBias] = useState(0);
  const [isInviteEmailError, setEmailError] = useState(true);

  const handleChange = (inputValue) => setFieldValue(field.name, inputValue);

  useEffect(() => {
    setRightOffset(selectRef?.current.getBoundingClientRect().right);
  }, []);

  const handleInviteInputChange = (nextValue) => {
    if (nextValue.length >= 6) {
      const found = nextValue.match(EMAIL_REGEXP);

      if (found !== null) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    } else {
      setEmailError(true);
    }

    return nextValue;
  };

  return (
    <MultiSelectContainer ref={selectRef}>
      <Select
        {...field}
        {...props}
        isMulti
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          IndicatorsContainer: () => null,
          Option: InviteOption,
          Input: InviteInput,
          NoOptionsMessage: InviteNoOptionMessage,
        }}
        noOptionsData={noOptionsMessage}
        placeholder={placeholder}
        styles={multiSelectStyles}
        options={options}
        menuBias={menuBias}
        rigthOffset={rigthOffset}
        menuWidth={menuWidth}
        menuPosition="fixed"
        menuPlacement="bottom"
        maxMenuHeight={maxMenuHeight}
        status={status}
        data={options}
        isInviteEmailError={isInviteEmailError}
        changeMenuBias={changeMenuBias}
        onChange={handleChange}
        onInputChange={fieldType === 'Invite' ? handleInviteInputChange : null}
      />
    </MultiSelectContainer>
  );
}

MultiSelect.defaultProps = {
  placeholder: 'Select...',
  maxMenuHeight: 300,
  menuWidth: 250,
  fieldType: 'multiSelect',
};

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  menuWidth: PropTypes.number,
  fieldType: PropTypes.string,
};

export default MultiSelect;
