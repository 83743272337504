import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'styles';
import { ProfileIconWrapper } from './ProfileIconStyles';

function ProfileIcon({ iconText, color }) {
  return (
    <ProfileIconWrapper name="profileIcon" color={color}>
      {iconText}
    </ProfileIconWrapper>
  );
}

ProfileIcon.propTypes = {
  iconText: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ProfileIcon.defaultProps = {
  color: colors.BRAND_COLOR,
};

export default ProfileIcon;
