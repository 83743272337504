import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import DateFnsAdapter from '@date-io/date-fns';

import { Tooltip } from 'components';
import { NotificationDot } from 'elements';
import { actionTypes } from 'modules/Feedback/MyFeedback/redux/myFeedbackSlice';
import {
  WorkItemContainer,
  WorkItemTitle,
  ArrowIconStyled,
  WorkItemLeftPanel,
  WorkItemRightPanel,
  WorkItemPanelBlock,
  WatchIconStyled,
  WorkItemDateText,
  WorkItemUserName,
} from './WorkItemStyles';

const dateFns = new DateFnsAdapter();

function WorkItem({
  id,
  title,
  date = '05/08/2020',
  rightPanel,
  userShortName,
  userFullName,
  push: navigate,
  isNew,
  link,
}) {
  const dispatch = useDispatch();

  const navigateToPage = () => {
    dispatch(actionTypes.getMyWorkItemRequest(id));
    return navigate(`${link}/${id}`);
  };

  return (
    <WorkItemContainer onClick={navigateToPage}>
      <WorkItemLeftPanel>
        {userShortName && (
          <Tooltip title={userFullName}>
            <WorkItemUserName>{userShortName}</WorkItemUserName>
          </Tooltip>
        )}
        <WorkItemTitle>{title}</WorkItemTitle>
      </WorkItemLeftPanel>

      <WorkItemRightPanel>
        {isNew && <NotificationDot />}
        <WorkItemPanelBlock>
          <WatchIconStyled new={`${isNew}`} />
          <WorkItemDateText new={`${isNew}`}>
            {dateFns.format(dateFns.date(date), 'MM/dd/yyyy')}
          </WorkItemDateText>
        </WorkItemPanelBlock>
        {rightPanel}
        <ArrowIconStyled />
      </WorkItemRightPanel>
    </WorkItemContainer>
  );
}

WorkItem.defaultProps = {
  userShortName: '',
  userFullName: '',
};

WorkItem.propTypes = {
  id: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rightPanel: PropTypes.node.isRequired,
  userShortName: PropTypes.string,
  userFullName: PropTypes.string,
};

export default connect(null, { push })(WorkItem);
