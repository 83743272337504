import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationDot } from 'elements';
import { getCurrentPathname } from 'modules/Navigation/redux/selectors';
import { sideBarLinksData } from 'modules/Navigation/constants';

import { getUnreadItemsNotification } from 'modules/Dashboard/ColleaguesWork/redux/selectors';
import { getUnreadNotification } from 'modules/Dashboard/MyWork/redux/selectors';
import { WorkItemsContainer, NavLink, NavTitle, Item } from './SideBarStyles';

function SideBarNavigation({ opened }) {
  const pathname = useSelector(getCurrentPathname);
  const colleaguesWorkNotification = useSelector(getUnreadItemsNotification);
  const myWorkNotifications = useSelector(getUnreadNotification);

  return (
    <WorkItemsContainer isOpened={opened}>
      {sideBarLinksData.map(({ title, link, icon: Icon, label }) => (
        <Item isActive={pathname === link} key={`side-bar-item-${title}`}>
          <NavLink to={link} exact opened={`${opened}`}>
            <Icon />
            {opened ? <NavTitle>{title}</NavTitle> : null}
            {label === 'CW'
              ? colleaguesWorkNotification && <NotificationDot />
              : myWorkNotifications && <NotificationDot />}{' '}
            {/* //TODO: Rewrite */}
          </NavLink>
        </Item>
      ))}
    </WorkItemsContainer>
  );
}

SideBarNavigation.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default memo(SideBarNavigation);
