import { put, all, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { pageRoutes } from 'modules/Navigation/constants';
import { requestWrapper as request } from 'utils';

import api from 'services';

import { createAccountDataSelector } from 'modules/Auth/CreateAccount/redux/selectors';
import { successSubmitPhoneDataSelector } from './selectors';
import { actionTypes } from './createCompanySlice';

import {
  submitPhoneNumberNormalizer,
  submitPhoneNumberSuccessNormalizer,
  resendCondeRequestNormalizer,
  verifyCodeRequestNormalizer,
} from './normalizer';

function* submitPhoneNumber({ payload: { data, errorDictionary, setErrors } }) {
  const createAccountData = yield select(createAccountDataSelector);

  const submitPhoneData = { ...createAccountData, ...data };
  const normalizedData = submitPhoneNumberNormalizer(submitPhoneData);

  const { response, error } = yield request(
    api.submitPhoneNumber,
    normalizedData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    const dataFoResendCode = submitPhoneNumberSuccessNormalizer(response, data);

    yield put(actionTypes.submitPhoneNumberSuccess(dataFoResendCode));
  } else {
    if (error?.status === 302) {
      yield put(push(pageRoutes.CREATE_ACCOUNT));
    }

    yield put(actionTypes.submitPhoneNumberError());
  }
}

function* resendCode({ payload: { phoneNumber, errorDictionary } }) {
  const data = yield select(successSubmitPhoneDataSelector);

  const resendCodeData = resendCondeRequestNormalizer(data, phoneNumber);

  const { response } = yield request(
    api.resendCode,
    resendCodeData,
    errorDictionary,
  );

  if (response) {
    yield put(actionTypes.resendCodeSuccess());
  } else {
    yield put(actionTypes.resendCodeError());
  }
}

function* verifyCode({ payload: { data, errorDictionary, setErrors } }) {
  const successSubmitPhoneData = yield select(successSubmitPhoneDataSelector);

  const dataForNormalizer = {
    ...successSubmitPhoneData,
    ...data,
  };

  const verifyPhoneNumberData = verifyCodeRequestNormalizer(dataForNormalizer);

  const { response } = yield request(
    api.verifyPhoneNumber,
    verifyPhoneNumberData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    yield put(actionTypes.verifyCodeSuccess());
    yield put(push(pageRoutes.CHECK_EMAIL));
  } else {
    yield put(actionTypes.verifyCodeError());
  }
}

export default function* createCompanySaga() {
  yield all([
    takeLatest(actionTypes.submitPhoneNumberRequest, submitPhoneNumber),
    takeLatest(actionTypes.resendCodeRequest, resendCode),
    takeLatest(actionTypes.verifyCodeRequest, verifyCode),
  ]);
}
