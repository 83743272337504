import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionTypes } from 'modules/Dashboard/MyWork/redux/myWorkSlice';
import { getMyWork } from 'modules/Dashboard/MyWork/redux/selectors';

import EmptyState from 'modules/Dashboard/components/EmptyState';

import MyWorkItemsList from 'modules/Dashboard/MyWork/components/MyWorkItemsList';

function MyWorkContent() {
  const dispatch = useDispatch();
  const myWork = useSelector(getMyWork);

  const addWork = () => dispatch(actionTypes.openWorkItemModal());

  return myWork.length === 0 ? (
    <EmptyState
      clickableMessage="Add some work "
      message="items, track your progress while it's still fresh on your mind, and ask your team for real-time and relevent feedback!"
      onClickAction={addWork}
    />
  ) : (
    <MyWorkItemsList workItems={myWork} />
  );
}

export default MyWorkContent;
