import * as Yup from 'yup';

import { email } from 'utils/formsData';
import { NAME_VALIDATION } from 'utils/formsData/regexp';

export const textFieldsData = {
  firstName: {
    type: 'text',
    label: 'First Name',
  },
  lastName: {
    type: 'text',
    label: 'Last Name',
  },
  email: {
    type: 'email',
    label: 'Work Email',
    disabled: true,
  },
};

export const errorDictionary = {
  IVPC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CVN0: {
    field: `This is a required field. Please only use characters A-Z a-z ' - , .`,
  },
};

// Schemas
export const basicInfoFormSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  lastName: Yup.string()
    .trim()
    .required('Last Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  email,
});
