import React from 'react';
import { useSelector } from 'react-redux';

import { getColleaguesWork } from 'modules/Dashboard/ColleaguesWork/redux/selectors';

import EmptyState from 'modules/Dashboard/components/EmptyState';

import ColleaguesWorkItemsList from '../ColleaguesWorkItemsList';

function ColleaguesWorkContent() {
  const colleguesWork = useSelector(getColleaguesWork);

  const inviteColleagues = () => {};

  return colleguesWork.length === 0 ? (
    <EmptyState
      message="Invite your colleagues to join so they can also track their accomplishments and you can provide them with feedback on work items, too!"
      onClickAction={inviteColleagues}
    />
  ) : (
    <ColleaguesWorkItemsList workItems={colleguesWork} />
  );
}

export default ColleaguesWorkContent;
