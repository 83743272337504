import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { toastSelector } from 'modules/Toast/redux/selectors';

const Toast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const toastData = useSelector(toastSelector);

  useEffect(() => {
    const { message, type } = toastData;

    if (message) {
      enqueueSnackbar(message, {
        variant: type,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 6000,
      });
    }
  }, [toastData, enqueueSnackbar]);

  return <></>;
};
export default Toast;
