import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getPeople } from 'modules/Dashboard/MyWork/redux/selectors';

import { actionTypes as actionTypesFeedback } from 'modules/Feedback/MyFeedback/redux/myFeedbackSlice';

import LoadingState from 'modules/Dashboard/components/LoadingState';
import {
  getPersonInitials,
  getPersonFullName,
} from 'modules/Profile/redux/selectors';
import { SideBar } from 'modules/Navigation';
import Header from '../components/Header';
import FeedbackLeftPanel from '../components/FeedbackTopPanel';
import {
  FeedbackPageWrapper,
  FeedbackContentWrapper,
  FeedbackContent,
} from '../components/SharedStyledComponents';
import EmptyState from './components/EmptyState';
import MyFeedbackHeader from './components/MyFeedbackHeader';
import MyFeedbackItemsList from './components/MyFeedbackItemsList';
import { getActiveItem } from './redux/selectors';

function MyFeedback({ id, push: navigate }) {
  const dispatch = useDispatch();
  const workItem = useSelector(getActiveItem);
  const revieweeInitials = useSelector(getPersonInitials);
  const { firstName, lastName } = useSelector(getPersonFullName);
  const collaboratorsCompany = useSelector(getPeople);

  const { work } = workItem;

  const navigateToPage = () => navigate('/myWork');

  const handleAddClick = () => {
    dispatch(actionTypesFeedback.openAddTeamModal(id));
  };

  useEffect(() => {
    if (!work || work.id !== id) {
      dispatch(actionTypesFeedback.getMyWorkItemRequest(id));
    }
  }, [collaboratorsCompany, dispatch, id, navigate, work, workItem]);
  console.log(work);
  return (
    <FeedbackPageWrapper>
      <SideBar isHidden />
      {work && work.id === id ? (
        <>
          <Header
            title={work.title}
            ownerInitials={revieweeInitials}
            firstName={firstName}
            lastName={lastName}
            handleBackClick={navigateToPage}
          />
          <FeedbackContentWrapper>
            <FeedbackLeftPanel
              description={work.description}
              createdDate={work.created_on}
              updatedDate={work.updated_on}
              collaborators={work.collaborators || []}
              stakeholders={work.stakeholders || []}
              handleAddClick={handleAddClick}
            />
            <FeedbackContent>
              <MyFeedbackHeader feedbacksCount={work.feedback.length} />
              {work.feedback.length === 0 ? (
                <EmptyState onClickAction={handleAddClick} />
              ) : (
                <MyFeedbackItemsList
                  workItemId={work.id}
                  feedbackItems={work.feedback} // TODO: move in normalizer
                  prompts={work.prompts}
                />
              )}
            </FeedbackContent>
          </FeedbackContentWrapper>
        </>
      ) : (
        <LoadingState />
      )}
    </FeedbackPageWrapper>
  );
}

MyFeedback.propTypes = {
  id: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(null, { push })(MyFeedback);
