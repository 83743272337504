import styled from 'styled-components';

import { breakpoints, space } from 'styles';

export const CreateAccountIntroContainer = styled.div`
  margin: 0;

  @media (min-width: ${breakpoints.RG}) {
    margin-right: ${space.XS};
  }
`;
