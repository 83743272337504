import * as Yup from 'yup';

import { dateOfBirth } from 'utils/formsData';

export const errorDictionary = {
  IVPC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CV0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CVD2: {
    field: 'Please select a year between 1920 and now.',
  },
  CVY1: {
    field: `Please select an option from the drop-down.`,
  },
  CVY2: {
    field: `Please select an option from the drop-down.`,
  },
};

export const selectsData = {
  gender: {
    label: 'How I identify my gender',
    options: [
      { value: '2', label: 'Female' },
      { value: '3', label: 'Male' },
      { value: '6', label: 'Trans Female (Male to Female)' },
      { value: '7', label: 'Trans Male (Female to Male)' },
      {
        value: '4',
        label: 'Non-binary / Genderqueer / Genderless / Agender / Bigender',
      },
      { value: '1', label: 'Other' },
      { value: '5', label: 'Prefer not to say' },
    ],
  },
  ethnicity: {
    label: 'Race/Ethnicity',
    options: [
      { value: '2', label: 'Asian' },
      {
        value: '3',
        label: 'African American or Black',
      },
      { value: '4', label: 'Caucasian or white' },
      { value: '5', label: 'Hispanic' },
      { value: '11', label: 'Latinx' },
      {
        value: '6',
        label: 'Middle Eastern or North African',
      },
      { value: '7', label: 'Native American' },
      {
        value: '8',
        label: 'Native Hawaiian or Pacific Islander',
      },
      {
        value: '10',
        label: 'Two or more',
      },
      { value: '1', label: 'Other' },
      { value: '9', label: 'Prefer not to say' },
    ],
  },
  religion: {
    label: 'Religion',
    options: [
      { value: '1', label: 'Agnostic' },
      { value: '2', label: 'Atheist' },
      { value: '3', label: 'Buddhist' },
      { value: '4', label: 'Christian' },
      { value: '5', label: 'Catholic' },
      { value: '6', label: 'Hindu' },
      { value: '7', label: 'Jewish' },
      { value: '8', label: 'Muslim' },
      { value: '9', label: 'Sikh' },
      {
        value: '10',
        label: 'Spiritual but not religious',
      },
      { value: '0', label: 'Other' },
      { value: '11', label: 'Prefer not to say' },
    ],
  },
  lgbtqStatus: {
    label: 'My Sexual Orientation',
    options: [
      { value: '5', label: 'Straight / Heterosexual' },
      { value: '6', label: 'Gay' },
      { value: '7', label: 'Lesbian' },
      { value: '8', label: 'Bisexual' },
      { value: '9', label: 'Questioning' },
      { value: '1', label: 'Other' },
      { value: '4', label: 'Prefer not to say' },
    ],
  },
};

// Schemas
export const validationSchema = Yup.object().shape({
  gender: Yup.string().nullable(),
  ethnicity: Yup.string().nullable(),
  religion: Yup.string().nullable(),
  lgbtqStatus: Yup.string().nullable(),
  yearOfBirth: dateOfBirth,
  hasDisability: Yup.bool().nullable(),
  extraOne: Yup.bool().when(
    [
      'gender',
      'ethnicity',
      'religion',
      'lgbtqStatus',
      'yearOfBirth',
      'hasDisability',
    ],
    {
      is: (
        gender,
        ethnicity,
        religion,
        lgbtqStatus,
        yearOfBirth,
        hasDisability,
      ) =>
        !(
          Boolean(gender) ||
          Boolean(ethnicity) ||
          Boolean(religion) ||
          Boolean(lgbtqStatus) ||
          Boolean(yearOfBirth) ||
          hasDisability !== null
        ),
      then: Yup.bool().required(),
      otherwise: Yup.bool(),
    },
  ),
});
