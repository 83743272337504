import React from 'react';
import PropTypes from 'prop-types';
import { ProfileMenu } from 'modules/Navigation';

import {
  HeaderWrapper,
  HeaderBackRedirect,
  HeaderTitle,
  HeaderTitleWrapper,
  HeaderProfileBlock,
  HeaderCreatorUserName,
} from './HeaderStyles';

function Header({
  title,
  firstName,
  lastName,
  creatorUserName,
  ownerInitials,
  handleBackClick,
}) {
  return (
    <HeaderWrapper>
      <HeaderBackRedirect title="Back" onClick={handleBackClick} />
      <HeaderTitleWrapper>
        <HeaderTitle>
          {creatorUserName && (
            <HeaderCreatorUserName>{creatorUserName}</HeaderCreatorUserName>
          )}
          {title}
        </HeaderTitle>
        <HeaderProfileBlock>
          <ProfileMenu
            iconText={ownerInitials}
            firstName={firstName}
            lastName={lastName}
          />
        </HeaderProfileBlock>
      </HeaderTitleWrapper>
    </HeaderWrapper>
  );
}

Header.defaultProps = {
  creatorUserName: '',
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  creatorUserName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  ownerInitials: PropTypes.string.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};

export default Header;
