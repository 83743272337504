import styled from 'styled-components';
import { typography, colors, breakpoints } from 'styles';
import { ArrowRight } from 'assets';

export const HeaderWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  padding: 27px 80px 27px 100px;
  jusify-content: start;
  align-items: center;
  background-color: white;
  z-index: 10;
`;

export const HeaderTitleWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  width: 94%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    margin-right: 20px;
    min-width: 40px;
  }
`;

export const HeaderTitle = styled.div`
  font-size: ${typography.size.LG};
  font-family: ${typography.font.TITLE};
  display: flex;
  flex-direction: row;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderBackRedirect = styled(ArrowRight)`
  height: 30px;
  width: 30px;
  min-width: 30px;
  transform: rotate(180deg);
  cursor: pointer;
`;

export const HeaderProfileBlock = styled.div`
  & > div {
    width: auto;
    min-width: 80px;
    border-left: none;
  }
`;

export const HeaderCreatorUserName = styled.div`
  font-size: 18px;
  font-family: ${typography.font.TEXT};
  display: flex;
  align-self: center;
  white-space: nowrap;
  padding: 5px;
  color: white;
  margin-right: 10px;
  border-radius: 5px;
  background-color: ${colors.DELIVARABLE_TAG};
  max-width: 180px;

  @media (min-width: ${breakpoints.RG}) {
    max-width: none;
  }
`;
