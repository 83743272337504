import React from 'react';

import { CreateCompanyForm, CreateCompanyIntro } from 'modules/Auth';
import { TopBarNavigator } from 'modules/Navigation';

import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from 'elements';

import { topBarDataForCreateAccount } from 'modules/Navigation/constants';

function CreateCompany() {
  return (
    <AuthPageWrapper>
      <NavigationBarContainer>
        <TopBarNavigator isOneItem oneItemData={topBarDataForCreateAccount} />
      </NavigationBarContainer>
      <PageContainer>
        <CreateCompanyIntro />
        <CreateCompanyForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default CreateCompany;
