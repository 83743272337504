import React, { useEffect } from 'react';
import { MyWorkContent } from 'modules/Dashboard';
import { DashboardWrapper } from 'elements';
import firebase from 'firebase/app';
import 'firebase/analytics';

function MyWork() {
  return (
    <DashboardWrapper>
      <MyWorkContent />
    </DashboardWrapper>
  );
}

export default MyWork;
