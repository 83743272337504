import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ArrowRight } from 'assets';
import { colors, typography, space } from 'styles';

export const ProfileMenuWrapper = styled.div`
  width: 200px;
  border-left: solid 1px ${colors.OUTLINES};
`;

export const ProfileMenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space.SM};
  height: 100%;
`;

export const ProfileIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-family: ${typography.font.SITE_NAME};
  font-size: ${typography.size.MD};
  color: ${colors.WHITE};
  background-color: ${colors.BRAND_COLOR};
  border-radius: 25px;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const UserMenu = styled(Menu)`
  margin-top: 19px;

  .MuiPaper-root {
    width: 200px;
    border: solid 1px ${colors.OUTLINES};
    box-shadow: 0 2px 24px 0 ${colors.SHADOWS};
  }

  .MuiMenu-paper {
    overflow-x: visible;
    overflow-y: visible;
  }

  .MuiButtonBase-root {
    font-family: ${typography.font.TEXT};
    font-size: ${typography.size.XS};
    color: ${colors.PRIMARY};
  }

  .MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  ul {
    padding: 0;

    li {
      padding: 5px ${space.XS};
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      z-index: 10;
    }

    &:after {
      right: 90px;
      top: -11px;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent ${colors.WHITE} transparent;
    }

    &:before {
      right: 89px;
      top: -12px;
      border-style: solid;
      border-width: 0 9px 11px 9px;
      border-color: transparent transparent ${colors.OUTLINES} transparent;
    }
  }
`;

export const UserMenuItemList = styled.div`
  padding: 15px 0;
`;

export const UserMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    justify-content: space-between;
  }
`;

export const ArrowIcon = styled(ArrowRight)``;

export const UserMenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space.XS};
  border-bottom: solid 1px ${colors.OUTLINES};
  outline: none;
`;

export const UserMenuHeaderTitle = styled.span`
  margin: 0;
  padding: 0;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.SM};
  color: ${colors.PRIMARY};
  text-align: center;
`;

export const UserMenuHeaderDescription = styled.span`
  margin-top: 5px;
  padding: 0;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  color: ${colors.ERROR};
  cursor: pointer;

  &:active {
    opacity: 0.75;
  }
`;

export const MenuLink = styled.a`
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: ${colors.PRIMARY};
  text-decoration: none;
`;
