import { all, put, select, takeLatest } from 'redux-saga/effects';

import { requestWrapper as request } from 'utils';
import api from 'services';

import { openModal } from 'modules/Modal/redux/modalSlice';
import { MODAL_TYPES } from 'modules/Modal/constants';
import { getRecaptchaToken } from 'modules/Recaptcha/redux/selectors';
import { actionTypes as recaptchaActionTypes } from 'modules/Recaptcha/redux/recaptchaSlice';
import { conctactUsRequestNormalizer } from './normalizer';

import { actionTypes } from './contactUsSlice';

function* contactUs({
  payload: { data, setErrors, errorDictionary, resetForm, setSubmitting },
}) {
  const recaptchaToken = yield select(getRecaptchaToken);
  const contactUsData = conctactUsRequestNormalizer(data, recaptchaToken);

  const { response } = yield request(
    api.contactUs,
    contactUsData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    yield put(actionTypes.contactUsSuccess());
    yield put(openModal({ modalType: MODAL_TYPES.CONTACT_US_MODAL }));
    resetForm({});
  } else {
    setSubmitting(false);
    yield put(actionTypes.contactUsError());
  }

  yield put(recaptchaActionTypes.refreshRecapthca());
}

export default function* contactUsSaga() {
  yield all([takeLatest(actionTypes.contactUsRequest, contactUs)]);
}
