import styled from 'styled-components';
import { colors, typography } from 'styles';
import { AddIconRegular } from 'assets';

export const FeedbackCollaboratorsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5px;
`;

export const FeedbackText = styled.div`
  color: ${colors.PRIMARY};
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  margin: 0px 5px;
`;

export const FeedbackIconsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;

  & div {
    margin-right: 10px;
    width: 35px;
    height: 35px;
  }
`;

export const AddIconStyled = styled(AddIconRegular)`
  width: 35px;
  height: 35px;
  cursor: pointer;
  &:hover {
    & > g {
      opacity: 0.8;
    }
  }
`;
