import { call, put } from 'redux-saga/effects';

import { actionTypes as authActionTypes } from 'modules/Auth/AuthProvider/redux/authProviderSlice';
import { actionTypes } from 'modules/Toast/redux/toastSlice';
import getErrorsByErrorType from './getErrorsByErrorType';

const requestWrapper = (
  saga,
  data,
  errorDictionary = {},
  setErrors,
  parseErrorsByErrorType = getErrorsByErrorType,
) =>
  call(function* sagaRequest() {
    try {
      const response = yield call(saga, data);
      return { response };
    } catch (error) {
      if (error?.status === 401) {
        yield put(authActionTypes.logoutRequest());
        yield put(
          actionTypes.showErrorToast(
            'For security reasons, you have been logged out due to extended inactivity. Please log back in to continue using Evalueme.',
          ),
        );
      } else {
        const { toastErrorArray, formErrorsObject } = parseErrorsByErrorType(
          error,
          errorDictionary,
        );

        if (Object.keys(formErrorsObject).length !== 0 && setErrors) {
          setErrors(formErrorsObject);
        }

        if (Object.keys(toastErrorArray).length !== 0) {
          yield put(actionTypes.showErrorToastArray(toastErrorArray));
        }
      }

      return { error };
    }
  });

export default requestWrapper;
