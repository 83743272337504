import React, { useEffect } from 'react';
import { ColleaguesWorkContent } from 'modules/Dashboard';
import { DashboardWrapper } from 'elements';

function ColleaguesWork() {
  useEffect(() => {
    // #GTAG
    gtag('event', 'page_view', {
      page_title: 'Colleagues Work',
      page_path: window.location.origin + window.location.pathname,
    });
  });
  return (
    <DashboardWrapper>
      <ColleaguesWorkContent />
    </DashboardWrapper>
  );
}

export default ColleaguesWork;
