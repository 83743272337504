import styled from 'styled-components';

import { colors } from 'styles';

export const SponsorsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.PRIMARY};
  margin: 0px;
  padding: 0px;
`;
export const SponsorsList = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: ${colors.SPONSORS_BACKGROUND};
  position: relative;
  bottom: 0;
  text-align: center;
`;
