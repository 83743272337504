import styled from 'styled-components';

export const SponsorLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

// not sure if I need to add styles or force dimensions from parent
export const SponsorImage = styled.img`
  max-height: 130px;
  padding: 10px;
  margin: 0;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
