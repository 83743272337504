import React from 'react';

import { AuthPageWrapper, PageContainer } from 'elements';
import { CheckEmailSection } from 'modules/Auth';

function CheckEmail() {
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <CheckEmailSection />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default CheckEmail;
