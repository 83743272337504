import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { actionTypes } from 'modules/Auth/CreateAccount/redux/createAccountSlice';
import { createAccounFetchingSelector } from 'modules/Auth/CreateAccount/redux/selectors';

import {
  FormTextField,
  FormSubmitButton,
  FormFieldMap,
  FormCheckbox,
} from 'components';

import {
  textFieldsData,
  createAccountFormSchema,
  createAccountFormInitialValues,
  errorDictionary,
} from './formsData';

import TermsAndConditions from './TermsAndConditions';

import {
  FormWrapper,
  Form,
  FormTitle,
  FormFieldWrapper,
  ButtonControl,
  ButtonContainer,
} from './CreateAccountFormStyles';

function CreateAccountForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(createAccounFetchingSelector);

  const handleSubmitButton = (data, { setErrors }) => {
    dispatch(
      actionTypes.createAccountRequest({
        data,
        errorDictionary,
        setErrors,
      }),
    );
  };

  return (
    <FormWrapper>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={createAccountFormInitialValues}
        validationSchema={createAccountFormSchema}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>Create Account</FormTitle>
            <FormFieldWrapper>
              <FormFieldMap
                horizontal
                textFieldsData={textFieldsData}
                textField={FormTextField}
              />
            </FormFieldWrapper>
            <ButtonControl>
              <FormCheckbox
                name="termsAndConditions"
                checked={values.termsAndConditions}
                label={<TermsAndConditions />}
              />
              <ButtonContainer>
                <FormSubmitButton
                  text="Let's Get Started!"
                  isFetching={isFetching}
                  disabled={isFetching || !isValid}
                />
              </ButtonContainer>
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
}

export default CreateAccountForm;
