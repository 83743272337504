import React, { useEffect } from 'react';
import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from 'elements';

import { ContactUsForm, Intro } from 'modules/ContactUs';

function ContactUs() {
  useEffect(() => {
    // #GTAG
    gtag('event', 'page_view', {
      page_title: 'Contact Us',
      page_path: window.location.origin + window.location.pathname,
    });
  });

  return (
    <AuthPageWrapper>
      <NavigationBarContainer />
      <PageContainer>
        <Intro />
        <ContactUsForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default ContactUs;
