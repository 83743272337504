import styled from 'styled-components';

import { LogoSmall, LogoMedium, LogoLarge, CloseIcon as Close } from 'assets';
import { CompanyTitle } from 'elements';

import { colors, breakpoints, space, typography } from 'styles';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: ${space.XL};
  background-color: ${colors.WHITE};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: ${space.XXS};
  right: ${space.XXS};

  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled(Close)``;

export const Logo = styled.img.attrs({
  src: LogoLarge,
  srcSet: `${LogoSmall} 96w, ${LogoMedium} 192w, ${LogoLarge} 288w`,
  sizes: `
    (max-width: ${breakpoints.SM}) 35px,
    (max-width: ${breakpoints.LG}) 35px,
    35px
  `,
})``;

export const LogoTitle = styled(CompanyTitle)`
  margin-left: 5px;
  color: ${colors.PRIMARY};
`;

export const ContentContainer = styled.div`
  margin-top: ${space.SM};
`;

export const ConentTitle = styled.p`
  text-align: center;
  margin: 0 0 ${space.XS};
  padding: 0;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.LG};
  color: ${colors.PRIMARY};
`;

export const ContentDescription = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.SM};
  color: ${colors.PRIMARY};
`;
