import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ContactUsModal } from 'modules/ContactUs';
import AddWorkItemModal from 'modules/Dashboard/MyWork/components/AddWorkItemModal';
import AddTeamModal from 'modules/Feedback/MyFeedback/components/AddTeamModal';
import DialogStyled from './styles';

import { isModalOpenSelector, getModalType } from './redux/selectors';
import { closeModal } from './redux/modalSlice';

import { MODAL_TYPES } from './constants';

const MODALS_MAPPING = {
  [MODAL_TYPES.CONTACT_US_MODAL]: ContactUsModal,
  [MODAL_TYPES.ADD_WORK_ITEM_MODAL]: AddWorkItemModal,
  [MODAL_TYPES.ADD_TEAM_MODAL]: AddTeamModal,
};

function Modal() {
  const dispatch = useDispatch();

  const isOpen = useSelector(isModalOpenSelector);
  const modalType = useSelector(getModalType);

  const handleClose = () => dispatch(closeModal());

  const ModalComponent = MODALS_MAPPING[modalType];

  return ModalComponent ? (
    <DialogStyled
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
      fullScreen={false}
    >
      <ModalComponent handleClose={handleClose} />
    </DialogStyled>
  ) : null;
}

export default React.memo(Modal);
