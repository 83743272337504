import React from 'react';

import { ThankYouPageSection, ThankYouForm } from 'modules/Waitlist';
import { AuthPageWrapper, PageContainer } from 'elements';

function ThankYou() {
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <ThankYouPageSection />
        <ThankYouForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default ThankYou;
