import { put, all, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { pageRoutes } from 'modules/Navigation/constants';

import { requestWrapper as request } from 'utils';

import api from 'services';

import { getAuthenticatedToken } from 'modules/Auth/AuthProvider/redux/selectors';
import { actionTypes as authActionTypes } from 'modules/Auth/AuthProvider/redux/authProviderSlice';
import { actionTypes } from './createAccountSlice';

import { createAccountRequestNormalizer } from './normalizer';

function* createAccount({ payload: { data, errorDictionary, setErrors } }) {
  const normalizedData = createAccountRequestNormalizer(data);
  const token = yield select(getAuthenticatedToken);

  // TBD: Solution for MVP
  if (token) {
    yield put(authActionTypes.logoutRequest());
  }

  const { response, error } = yield request(
    api.createAccount,
    normalizedData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    yield put(actionTypes.createAccountSuccess());
    yield put(push(pageRoutes.CHECK_EMAIL));
  } else {
    if (error?.status === 301) {
      yield put(actionTypes.saveCreateAccountData(data));
      yield put(push(pageRoutes.CREATE_COMPANY));
    }

    yield put(actionTypes.createAccountError());
  }
}

export default function* createAccountSaga() {
  yield all([takeLatest(actionTypes.createAccountRequest, createAccount)]);
}
