import { createSlice } from '@reduxjs/toolkit';

const profileInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  demographics: {
    gender: null,
    ethnicity: null,
    religion: null,
    lgbtqStatus: null,
    hasDisability: null,
    yearOfBirth: null,
  },
  positionInfo: {
    department: '',
    title: '',
    manager: '',
    salary: '',
    dateStarted: null,
    dateStartedWithManager: null,
    dateOfLastPromotion: null,
    dateOfLastRaise: null,
  },
  currentStep: 0,
  passedSteps: {
    'Basic Info': true,
    Demographics: false,
    Disability: false,
    'Position Info': false,
    LinkedIn: false,
  },
  disability: [],
  linkedin: '',
  isInitialized: false,
  isFirstIncompleteStepSet: false,
  accountOccupancy: null,
  isAccountFull: false,
};

const profileSlice = createSlice({
  name: 'PROFILE',
  initialState: profileInitialState,
  reducers: {
    getPersonInfoRequest: () => {},
    getPersonInfoSuccess: (state, action) => {
      const { accountOccupancy } = action.payload;
      const isAccountFull = accountOccupancy === 100;
      console.log('PERSON SUCCESS', action, state);
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
        isAccountFull,
      };
    },
    getPersonInfoError: () => {},
    setCurrentStep: (state, { payload: id }) => {
      state.currentStep = id;
    },
    setPassedSteps: (state, { payload: passedSteps }) => {
      state.passedSteps = passedSteps;
    },
    resetCurrentStep: (state) => {
      state.currentStep = 0;
    },
    navigateToTheNextStep: (state) => {
      state.currentStep += 1;
    },
    updateBasicInfoRequest: () => {},
    updateBasicInfoSuccess: () => {},
    updateBasicInfoError: () => {},
    updateDemographicsRequest: () => {},
    updateDemographicsSuccess: () => {},
    updateDemographicsError: () => {},
    setCurrentIncompleteStep: () => {},
    setCurrentIncompleteStepSuccess: (state) => {
      state.isFirstIncompleteStepSet = true;
    },
    updateDisabilityRequest: () => {},
    updateDisabilitySuccess: () => {},
    updateDisabilityError: () => {},
    updatePositionInfoRequest: () => {},
    updatePositionInfoSuccess: () => {},
    updatePositionInfoError: () => {},
    updateLinkedinRequest: () => {},
    updateLinkedinSuccess: () => {},
    updateLinkedinError: () => {},
    resetProfileData: () => profileInitialState,
  },
});

export const actionTypes = profileSlice.actions;

export default profileSlice.reducer;
