import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionTypes } from 'modules/Dashboard/ColleaguesWork/redux/colleaguesWorkSlice';
import { getIsAccountFull } from 'modules/Profile/redux/selectors';
import { getColleaguesWorkLoading } from 'modules/Dashboard/ColleaguesWork/redux/selectors';
import { pageRoutes } from 'modules/Navigation/constants';

import {
  DashboardBasis,
  NotificationField,
} from 'modules/Dashboard/components';
import LoadingState from 'modules/Dashboard/components/LoadingState';
import { ContentContainer } from 'modules/Dashboard/components/SharedStyledComponents';
import ColleaguesWorkContent from './ColleaguesWorkContent';

function ColleaguesWorkDashdoard() {
  const dispatch = useDispatch();
  const isAccountFull = useSelector(getIsAccountFull);

  const isColleguesWorkLoading = useSelector(getColleaguesWorkLoading);

  useEffect(() => {
    dispatch(actionTypes.getAllWorkRequest());
  }, [dispatch]);

  return (
    <DashboardBasis>
      <ContentContainer>
        {isColleguesWorkLoading ? <LoadingState /> : <ColleaguesWorkContent />}
      </ContentContainer>
      {!isAccountFull && <NotificationField link={pageRoutes.PROFILE} />}
    </DashboardBasis>
  );
}

export default ColleaguesWorkDashdoard;
