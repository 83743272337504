import { profileFieldMap } from 'modules/Profile/constants';

function getProfileErrors(error, errorDictionary) {
  const formErrorsObject = {};
  const toastErrorMessagesArray = [];
  const errorsArray = error?.data?.failed || null;

  const trackUncatchedError = () => {
    toastErrorMessagesArray.push(
      `We are having technical difficulties. Please try again. If it's still not working, please e-mail us at help@evalueme.ai`,
    );
  };

  if (errorsArray === null) {
    trackUncatchedError();
  } else {
    errorsArray.forEach((errorObject) => {
      const errorType = Object.keys(errorObject.errors)[0];
      const fieldName = profileFieldMap[errorObject.field_name];

      const errorDictionaryData = errorDictionary[errorType];

      if (errorDictionaryData?.toast) {
        toastErrorMessagesArray.push(errorDictionaryData.toast);
      } else if (errorDictionaryData?.field) {
        Object.assign(formErrorsObject, {
          [fieldName]: errorDictionaryData.field,
        });
      } else {
        trackUncatchedError();
      }
    });
  }

  const toastErrorArray = [...new Set(toastErrorMessagesArray)];

  return { formErrorsObject, toastErrorArray };
}

export default getProfileErrors;
