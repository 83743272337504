import styled from 'styled-components';

import Checkboxes from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { colors, typography, breakpoints } from 'styles';

export const Checkbox = styled(Checkboxes)`
  &.MuiCheckbox-root {
    height: min-content;
    width: min-content;
    padding: 5px;
    color: ${colors.SECONDARY_TEXT};
    :hover,
    :active,
    :focus {
      background-color: ${colors.CHECKBOX_EFFECT};
    }
  }

  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${colors.BRAND_COLOR};
  }

  &.MuiTypography-body1 {
    font-family: ${typography.font.TEXT};
    font-size: 4rem;
    color: ${colors.SECONDARY_TEXT};
  }
`;

export const CheckboxLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    margin: 0;
  }

  .MuiTypography-root {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @media (min-width: ${breakpoints.SM}) {
      font-size: ${typography.size.SM};
    }
  }

  .MuiTypography-body1 {
    font-family: ${typography.font.TEXT};
    color: ${colors.SECONDARY_TEXT};
  }

  .Mui-checked + span {
    color: ${colors.PRIMARY};
  }

  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    :hover {
      background-color: ${colors.CHECKBOX_EFFECT};
    }
  }
`;
