import { createSlice } from '@reduxjs/toolkit';

const authPrividerInitialState = {
  isAuthenticated: false,
  personId: '',
  companyId: '',
  token: '',
};

const authProviderSlice = createSlice({
  name: 'AUTH',
  initialState: authPrividerInitialState,
  reducers: {
    setAuthenticatedData: (state, action) => {
      const { isAuthenticated, token, person, company } = action.payload;
      console.log('EVER HAPPENS?');

      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.personId = person;
      state.companyId = company;
    },
    setRefreshedToken: (state, { payload }) => {
      console.log('ALSO EVER HAPPENS?');
      state.token = payload;
    },
    refreshTokenRequest: () => {},
    logoutRequest: () => authPrividerInitialState,
    authenticate: () => {
      console.log('HAPPENING?');
    },
  },
});

export const actionTypes = authProviderSlice.actions;

export default authProviderSlice.reducer;
