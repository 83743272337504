import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actionTypes } from 'modules/Auth/CreateCompany/redux/createCompanySlice';

import CreatePhoneForm from '../CreatePhoneForm';
import CreateVerificationCodeForm from '../CreateVerificationCodeForm';

import { CompanyContainer } from './CreateCompanyFormStyle';

function CreateCompanyForm() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actionTypes.resetCeateCompanyState());
    };
  }, [dispatch]);

  return (
    <CompanyContainer>
      <CreatePhoneForm />
      <CreateVerificationCodeForm />
    </CompanyContainer>
  );
}

export default CreateCompanyForm;
