import React from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

import { getPersonFullName } from 'modules/Profile/redux/selectors';
import { ProfileIcon, FormTextField } from 'components';

import { infoHeaderFieldsData } from './formsData';

import {
  InfoHeaderWrapper,
  InfoHeaderContainer,
  ProfileIconContainer,
  InfoHeaderFieldsWrapper,
  InfoHeadFieldControl,
  TexSection,
  MainText,
  AdditionalText,
} from './AddWorkItemModalStyles';

function InfoHeader() {
  const { firstName, lastName } = useSelector(getPersonFullName);
  const iconText = firstName.charAt(0).concat(lastName.charAt(0));

  return (
    <InfoHeaderWrapper>
      <InfoHeaderContainer>
        <ProfileIconContainer>
          <ProfileIcon iconText={iconText} />
        </ProfileIconContainer>
        <InfoHeaderFieldsWrapper>
          {infoHeaderFieldsData.map(
            ({ name, placeholder, multiline, rowsMax }) => (
              <InfoHeadFieldControl key={`info-header-field-${name}`}>
                <Field
                  name={name}
                  placeholder={placeholder}
                  multiline={multiline}
                  rowsMax={rowsMax}
                  component={FormTextField}
                />
              </InfoHeadFieldControl>
            ),
          )}
        </InfoHeaderFieldsWrapper>
      </InfoHeaderContainer>
      <TexSection>
        <MainText>
          You can tag your colleagues now or after you post your work item.{' '}
        </MainText>
        <AdditionalText>
          These people will be able to give you real-time feedback on your
          progress and achievements.
        </AdditionalText>
      </TexSection>
    </InfoHeaderWrapper>
  );
}

export default InfoHeader;
