import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDateString } from 'modules/Feedback/utils/getFormattedDateString';

import MyFeedbackGroupedUsers from 'modules/Feedback/MyFeedback/components/MyFeedbackGroupedUsers';
import {
  FeedbackLeftPanelWrapper,
  FeedbackPanelBlock,
  FeedbackDescription,
  FeedbackDateText,
  FeedbackDateBlock,
  FeedbackDateItemsBlock,
  FeedbackDateUnityBlock,
  FeedbackDescriptionText,
  CollapsedControlsButton,
  FeedbackDateTitle,
} from './FeedbackTopPanelStyles';

function FeedbackLeftPanel({
  description,
  collaborators,
  stakeholders,
  updatedDate,
  createdDate,
  handleAddClick,
}) {
  const descriptionRef = useRef(null);
  const [collapsed, toggleCollapse] = useState(true);
  const [isShowControls, setShowControls] = useState(false);

  useEffect(() => {
    if (
      !isShowControls &&
      descriptionRef.current &&
      descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight
    ) {
      setShowControls(true);
    }
  }, [isShowControls, descriptionRef]);

  return (
    <FeedbackLeftPanelWrapper>
      <FeedbackDateBlock>
        <FeedbackDescription>Description</FeedbackDescription>
        <FeedbackDateUnityBlock>
          <FeedbackDateItemsBlock>
            <FeedbackDateTitle>Created:</FeedbackDateTitle>
            <FeedbackDateText>
              {getFormattedDateString(createdDate)}
            </FeedbackDateText>
          </FeedbackDateItemsBlock>
          <FeedbackDateItemsBlock>
            <FeedbackDateTitle>Updated:</FeedbackDateTitle>
            <FeedbackDateText>
              {getFormattedDateString(updatedDate)}
            </FeedbackDateText>
          </FeedbackDateItemsBlock>
        </FeedbackDateUnityBlock>
      </FeedbackDateBlock>
      <FeedbackPanelBlock>
        <FeedbackDescriptionText
          collapsed={collapsed}
          lineClamp={2}
          ref={descriptionRef}
        >
          {description}
        </FeedbackDescriptionText>
        {isShowControls && (
          <CollapsedControlsButton onClick={() => toggleCollapse(!collapsed)}>
            {collapsed ? 'Show more' : 'Show less'}
          </CollapsedControlsButton>
        )}
      </FeedbackPanelBlock>

      {collaborators !== null && (
        <MyFeedbackGroupedUsers
          collaborators={collaborators}
          stakeholders={stakeholders}
          handleAddClick={handleAddClick}
        />
      )}
    </FeedbackLeftPanelWrapper>
  );
}

FeedbackLeftPanel.defaultProps = {
  collaborators: null,
  stakeholders: null,
  description: '',
};

FeedbackLeftPanel.propTypes = {
  description: PropTypes.string,
  collaborators: PropTypes.arrayOf(PropTypes.object),
  stakeholders: PropTypes.arrayOf(PropTypes.object),
  updatedDate: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
};

export default memo(FeedbackLeftPanel);
