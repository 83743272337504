import React from 'react';

import Header from 'modules/Dashboard/components/Header';
import { SideBar } from 'modules/Navigation';

import {
  DashboardContainer,
  DashboardContentContainer,
} from './DashboardBasisStyles';

function DashboardBasis({ children }) {
  return (
    <>
      <SideBar />
      <DashboardContainer>
        <Header />
        <DashboardContentContainer>{children}</DashboardContentContainer>
      </DashboardContainer>
    </>
  );
}

export default DashboardBasis;
