import React from 'react';

import {
  MobileRoadblockWrapper,
  LogoContainer,
  Logo,
  LogoTitle,
  TextContainer,
  Title,
  Description,
} from './MobileRoadblockStyles';

function MobileRoadblock() {
  return (
    <MobileRoadblockWrapper>
      <LogoContainer>
        <Logo />
        <LogoTitle>evalueme.ai</LogoTitle>
      </LogoContainer>
      <TextContainer>
        <Title>Try us on your laptop!</Title>
        <Description>
          Evalueme.ai is only available on laptop/desktop at the moment. We’re a
          startup with limited resources, so please bare with us as we grow.
        </Description>
      </TextContainer>
    </MobileRoadblockWrapper>
  );
}

export default MobileRoadblock;
