import { all, put, takeLatest } from 'redux-saga/effects';

import { requestWrapper as request } from 'utils';
import api from 'services';

import { actionTypes } from './colleaguesWorkSlice';

export function* getAllWork() {
  const { response } = yield request(api.getAllWork);

  if (response) {
    yield put(actionTypes.getAllWorkSuccess(response));
  } else {
    yield put(actionTypes.getAllWorkError());
  }
}

export default function* allWorkSaga() {
  yield all([takeLatest(actionTypes.getAllWorkRequest, getAllWork)]);
}
