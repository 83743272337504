import { createSlice } from '@reduxjs/toolkit';

const createAccountState = {
  createAccountData: {
    firstName: '',
    lastName: '',
    workEmail: '',
    inviteCode: '',
    termsAndConditions: '',
  },
  isFetching: false,
};

const createAccountSlice = createSlice({
  name: 'AUTH',
  initialState: createAccountState,
  reducers: {
    createAccountRequest: (state) => {
      state.isFetching = true;
    },
    createAccountSuccess: (state) => {
      state.isFetching = false;
    },
    saveCreateAccountData: (state, action) => {
      const {
        firstName,
        lastName,
        workEmail,
        inviteCode,
        termsAndConditions,
      } = action.payload;

      Object.assign(state, {
        createAccountData: {
          firstName,
          lastName,
          workEmail,
          inviteCode,
          termsAndConditions,
        },
      });
    },
    createAccountError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = createAccountSlice.actions;

const createAccountReducer = createAccountSlice.reducer;

export default createAccountReducer;
