import styled from 'styled-components';
import { colors, typography } from 'styles';

export const PrivacyContainer = styled.div`
  padding: 20px;
  font-family: sans-serif;
`;

export const PrivacyText = styled.p`
  margin-top: 0px;
  font-size: ${(props) =>
    props.size === 'large' ? typography.size.LG : typography.size.SM};
  color: ${(props) => (props.color ? props.color : colors.PRIMARY)};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
`;

export const PrivacyLink = styled.a``;

export const PrivacyHeader = styled.h4`
  font-weight: bold;
  margin-bottom: 0px;
`;

export const PrivacyList = styled.ul`
  padding-left: 20px;
  list-style: square;
  margin-bottom: 20px;
  + h4 {
    margin-bottom: 0;
  }
`;

export const PrivacyItem = styled.li`
  text-decoration: none;
`;
