import React from 'react';

import { IntroSection } from 'components';

import { ThankYouPageSectionWrapper } from './ThankYouPageSectionStyles';

function ThankYouPageSection() {
  return (
    <ThankYouPageSectionWrapper>
      <IntroSection
        title="Thank you for your interest in Evalueme!"
        description="Please let us know which topics most interest you, so that we only send you e-mails that you want to receive. "
      />
    </ThankYouPageSectionWrapper>
  );
}

export default ThankYouPageSection;
