import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { MultiSelect } from 'components';

import { InviteFieldControl, IviteFieldLabel } from './AddWorkItemModalStyles';

function InviteField({ name, label, placeholder, options, noOptionsMessage }) {
  return (
    <InviteFieldControl>
      <IviteFieldLabel>{label}</IviteFieldLabel>
      <Field
        name={name}
        fieldType="Invite"
        placeholder={placeholder}
        maxMenuHeight={132}
        noOptionsMessage={noOptionsMessage}
        options={options}
        component={MultiSelect}
      />
    </InviteFieldControl>
  );
}

InviteField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  noOptionsMessage: PropTypes.object.isRequired,
};

export default InviteField;
