import styled from 'styled-components';

import {
  SectionText as SectionTextElement,
  CompanyTitle,
  SectionTitle,
} from 'elements';
import { LogoSmall, LogoMedium, LogoLarge } from 'assets';

import { space, breakpoints } from 'styles';

export const IntroSectionWrapper = styled.div`
  flex: 1;
  flex-grow: initial;
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.RG}) {
    margin: 0;
    flex: 1;
  }
`;

export const IntroSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;

  @media (min-width: ${breakpoints.MD}) {
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 420px;
    min-width: 290px;
    text-align: start;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${space.SM} 0;
  padding-top: 0;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: start;
    width: 100%;
    margin: ${space.SM} 0 ${space.MD};
  }
`;

export const Logo = styled.img.attrs({
  src: LogoLarge,
  srcSet: `${LogoSmall} 96w, ${LogoMedium} 192w, ${LogoLarge} 288w`,
  sizes: `
    (max-width: ${breakpoints.SM}) 35px,
    (max-width: ${breakpoints.LG}) 35px,
    35px
  `,
})``;

export const LogoTitle = styled(CompanyTitle)`
  margin-left: 5px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.SM}) {
    margin-bottom: ${space.XS};
  }
`;

export const Title = styled(SectionTitle)`
  justify-content: center;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: flex-start;
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
`;

export const DescriptionText = styled(SectionTextElement)`
  justify-content: center;
  line-height: 26px;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: flex-start;
  }
`;

export const AdditionalComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${breakpoints.SM}) {
    margin-top: ${space.XL};
  }
`;
