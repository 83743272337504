import React from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { joinWaitListFetchingSelectorId } from 'modules/Waitlist/Join/redux/selectors';
import { industryWaitListFetchingSelector } from 'modules/Waitlist/IndustryType/redux/selectors';

import {
  FormFieldMap,
  FormSubmitButton,
  FormCheckboxMap,
  FormSelect,
  FormCheckboxWithTextField,
  FormTextField,
} from 'components';

import { actionTypes } from 'modules/Waitlist/IndustryType/redux/industryTypeSlice';

import {
  textFieldsData,
  industryTypeWaitListInitialValues,
  errorDictionary,
} from './formsData';

import {
  IndustryCheckboxesArray,
  roleSelectOptions,
  industrySelectOptions,
} from './constants';

import {
  FormContainer,
  Form,
  FormFieldWrapper,
  SelectWrapper,
  CheckboxSummary,
  CheckboxContainer,
  ButtonControl,
} from './IndustryTypeFormStyle';

function IndustryTypeForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(industryWaitListFetchingSelector);
  const id = useSelector(joinWaitListFetchingSelectorId);

  const handleSubmitButton = (formData, { setErrors }) => {
    const data = { ...formData, id };
    const { industryWaitListRequest } = actionTypes;

    dispatch(industryWaitListRequest({ data, errorDictionary, setErrors }));
  };

  return (
    <FormContainer>
      <Formik
        initialValues={industryTypeWaitListInitialValues}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <FormFieldWrapper>
              <FormFieldMap
                vertical
                textFieldsData={textFieldsData}
                textField={FormTextField}
              />
            </FormFieldWrapper>
            <SelectWrapper>
              <Field
                name="role"
                label="Your role in your company"
                component={FormSelect}
                selectOptions={roleSelectOptions}
              />
            </SelectWrapper>
            <SelectWrapper>
              <Field
                name="industry"
                label="Industry type"
                component={FormSelect}
                selectOptions={industrySelectOptions}
              />
            </SelectWrapper>
            <CheckboxSummary>
              What do you want to get updates on:
            </CheckboxSummary>
            <CheckboxContainer>
              <FormCheckboxMap checkboxObject={IndustryCheckboxesArray} />
            </CheckboxContainer>
            <FormCheckboxWithTextField
              textField={FormTextField}
              fieldName="other"
              setFieldValue={setFieldValue}
            />
            <ButtonControl>
              <FormSubmitButton
                text="Submit"
                disabled={isFetching}
                isFetching={isFetching}
              />
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default IndustryTypeForm;
