import * as Yup from 'yup';

import { email } from 'utils/formsData';
import {
  NAME_VALIDATION,
  INVITE_CODE_VALIDATION,
} from 'utils/formsData/regexp';

export const textFieldsData = {
  firstName: {
    type: 'text',
    label: 'First Name*',
  },
  lastName: {
    type: 'text',
    label: 'Last Name*',
  },
  workEmail: {
    type: 'email',
    label: 'Work Email*',
  },
  inviteCode: {
    label: 'Invite Code*',
  },
};

export const errorDictionary = {
  IVPCV2: {
    firstName: `Please only use the following characters: A-Z a-z ' - , .`,
  },
  IVPCV3: {
    lastName: `Please only use the following characters: A-Z a-z ' - , .`,
  },
  IVPCV4: { lastName: 'Must have username and must be an email address.' },
  IVPCV5: { termsAndConditions: 'Please, accept terms and conditions.' },
  IVPCV6: { workEmail: 'Email address must be company domain.' },
  IVPCV7: { inviteCode: 'Invite Code must be 10 characters and a-z and 0-9' },
  CP1: {
    workEmail:
      'User already exists. Please log in or <a href="/contactUs" target="_blank" class="ContactUs-Link">contact us</a> to reset your password.',
  },
  CP2: {
    workEmail:
      'User already exists. Please log in or <a href="/contactUs" target="_blank" class="ContactUs-Link">contact us</a> to reset your password.',
  },
  CP3: {},
  CP4: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CP5: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CP6: {
    toast: `This Invite Code is incorrect. If this problem continues, please email us at help@evalueme.ai`,
  },
};

// Schemas
export const createAccountFormSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  lastName: Yup.string()
    .trim()
    .required('Last Name should not be empty.')
    .matches(
      NAME_VALIDATION,
      `Please only use the following characters: A-Z a-z ' - , .`,
    ),
  workEmail: email,
  inviteCode: Yup.string()
    .trim()
    .required('Invite Code should not be empty.')
    .matches(
      INVITE_CODE_VALIDATION,
      'Code should be 10 characters A-Z and 0-9 only',
    ),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'Please, accept terms and conditions.',
  ),
});

// Initial values
export const createAccountFormInitialValues = {
  firstName: '',
  lastName: '',
  workEmail: '',
  inviteCode: '',
  termsAndConditions: false,
};
