import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ProfileIcon } from 'components';

import { colors } from 'styles';

import {
  FeedbackText,
  FeedbackIconsBlock,
  AddIconStyled,
  FeedbackCollaboratorsBlock,
} from './MyFeedbackGroupedUsersStyles';

const getInitials = (fn, ln, email) =>
  fn ? fn.charAt(0).concat(ln.charAt(0)) : email.charAt(0);

const tooltipGrouped = (users) =>
  users.map(({ first_name: firstName, last_name: lastName, email }, i) => (
    <div key={email}>
      {`${firstName !== null ? `${firstName} ${lastName}` : email}${
        i !== users.length - 1 ? ',' : ''
      }`}
      <br />
    </div>
  ));

const splitArray = (usersArray, key) => {
  if (usersArray.length <= 3) {
    return usersArray.map(
      ({ first_name: firstName, last_name: lastName, email }) => (
        <Tooltip
          key={`${key}-tooltip-${firstName}-${lastName}`}
          title={firstName !== null ? `${firstName} ${lastName}` : email}
        >
          <ProfileIcon
            key={`${key}-${firstName}-${lastName}`}
            iconText={getInitials(firstName, lastName, email)}
            color={colors.SECONDARY_TEXT}
          />
        </Tooltip>
      ),
    );
  }
  const newArray = usersArray.slice(2);
  const secArr = [usersArray[0], usersArray[1]];
  return (
    <>
      {secArr.map(({ first_name: firstName, last_name: lastName, email }) => (
        <Tooltip
          key={`${key}-tooltip-${email}`}
          title={firstName !== null ? `${firstName} ${lastName}` : email}
        >
          <ProfileIcon
            key={`${key}-${email}`}
            iconText={getInitials(firstName, lastName, email)}
            color={colors.SECONDARY_TEXT}
          />
        </Tooltip>
      ))}
      <Tooltip key={`${key}-tooltip-grouped`} title={tooltipGrouped(newArray)}>
        <ProfileIcon
          key={`${key}-grouped`}
          iconText={`+${newArray.length}`}
          color={colors.SECONDARY_TEXT}
        />
      </Tooltip>
    </>
  );
};

function MyFeedbackGroupedUsers({
  // TODO: Split componet on utils for future use and on componets for others pages
  collaborators,
  stakeholders,
  handleAddClick,
}) {
  return (
    <FeedbackCollaboratorsBlock>
      <FeedbackText>Collaborators:</FeedbackText>
      <FeedbackIconsBlock>
        {collaborators.length
          ? splitArray(collaborators, 'collaborators')
          : null}
        <AddIconStyled onClick={handleAddClick} />
      </FeedbackIconsBlock>
      <FeedbackText>Stakeholders:</FeedbackText>
      <FeedbackIconsBlock>
        {stakeholders.length ? splitArray(stakeholders, 'stakeholders') : null}
        <AddIconStyled onClick={handleAddClick} />
      </FeedbackIconsBlock>
    </FeedbackCollaboratorsBlock>
  );
}

MyFeedbackGroupedUsers.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.object).isRequired,
  stakeholders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(MyFeedbackGroupedUsers);
