import styled from 'styled-components';

import { space, colors, typography } from 'styles';

export const AddWorkItemModalWrapper = styled.div`
  max-width: 600px;
  padding: ${space.XS} 0;
  z-index: 2;
`;

export const Form = styled.form``;

export const ButtonControl = styled.div`
  width: 400px;
  height: 50px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${space.XS};
  margin-top: ${space.XS};

  ${ButtonControl}:first-child {
    margin-right: ${space.XXS};
  }
`;

export const TexSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${space.XS} ${space.LG};
`;

const Text = styled.div`
  font-family: ${typography.font.TEXT};
  text-align: center;
  color: ${colors.PRIMARY};
`;

export const MainText = styled(Text)`
  font-size: ${typography.size.LG};
  margin-bottom: 5px;
`;

export const AdditionalText = styled(Text)`
  font-size: ${typography.size.SM};
`;

// InviteFieldsSection styles
export const InviteFieldControl = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px 20px;
`;

export const InviteFieldsContainer = styled.div`
  border-top: solid 1px ${colors.OUTLINES};
  border-bottom: solid 1px ${colors.OUTLINES};

  ${InviteFieldControl}:not(:last-child) {
    border-bottom: solid 1px ${colors.OUTLINES};
  }
`;

export const IviteFieldLabel = styled.label`
  margin-right: ${space.XXS};
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};
  white-space: nowrap;
`;
