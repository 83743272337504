import styled from 'styled-components';

import { ArrowRight } from 'assets';
import { colors, space, typography, breakpoints } from 'styles';

export const NotificationFieldWrapper = styled.div`
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
`;

export const NotificationFieldContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 710px;
  color: ${colors.ERROR};
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.OUTLINES};
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 ${colors.SHADOWS};

  @media (min-width: ${breakpoints.LG}) {
    padding: 16px ${space.SM};
    width: 800px;
  }
`;

export const NotificationFieldContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ContentText = styled.span`
  margin: 0;
  padding: 0;
  font-size: ${typography.size.SM};
`;

export const Text = styled(ContentText)`
  padding-left: ${space.XXS};
  font-family: ${typography.font.TEXT};
`;

export const LinkText = styled(ContentText)`
  font-family: ${typography.font.TITLE};
  text-decoration: underline;
  cursor: pointer;

  &:active {
    opacity: 0.75;
  }
`;

export const NotificationCircleContainer = styled.div`
  width: 10px;
  height: 10px;
`;

export const NotificationCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.ERROR};
  border-radius: 5px;
`;

export const ArrowRightIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ArrowRightIcon = styled(ArrowRight)``;
