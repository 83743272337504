import {
  MyWorkIcon,
  ColleaguesWorkIcon,
} from './components/SediBar/SideBarStyles';

export const pageRoutes = {
  HOME: '/',
  WAIT_LIST: '/waitlist',
  LOG_IN: '/login',
  CREATE_ACCOUNT: '/createAccount',
  CREATE_COMPANY: '/createCompany',
  CREATE_PASSWORD: '/createPassword',
  WAIT_LIST_THANKS: '/waitlistThanks',
  CHECK_EMAIL: '/checkEmail',
  MY_WORK: '/myWork',
  COLLEGUES_WORK: '/colleaguesWork',
  FORGOT_PASSWORD: '/forgotPassword',
  CONTACT_US: '/contactUs',
  PROFILE: '/profile',
  MY_FEEDBACK: '/myFeedback',
  COLLEAGUES_FEEDBACK: '/colleaguesFeedback',
  TERMS: '/terms',
  PRIVACY: '/privacy',
};

export const ignoredPersonInfoRequestPages = [
  pageRoutes.HOME,
  pageRoutes.WAIT_LIST,
  pageRoutes.LOG_IN,
  pageRoutes.CREATE_ACCOUNT,
  pageRoutes.CREATE_COMPANY,
  pageRoutes.WAIT_LIST_THANKS,
  pageRoutes.CREATE_PASSWORD,
  pageRoutes.CHECK_EMAIL,
  pageRoutes.PROFILE,
  pageRoutes.CONTACT_US,
  pageRoutes.TERMS,
  pageRoutes.PRIVACY,
];

export const screensPathWithMobileDesign = [
  '/',
  '/waitlist',
  '/login',
  '/createAccount',
  '/createCompany',
  '/createPassword',
  '/waitlistThanks',
  '/checkEmail',
  '/contactUs',
  '/terms',
  '/privacy',
];

export const topBarNavigatorButtonsData = [
  { buttonTitle: 'Log In', pushRouteName: pageRoutes.LOG_IN },
  { buttonTitle: 'Create Account', pushRouteName: pageRoutes.CREATE_ACCOUNT },
  { buttonTitle: 'Join Waitlist', pushRouteName: pageRoutes.HOME },
];

export const topBarDataForCreateAccount = {
  text: 'Already have an account? ',
  buttonTitle: 'Log In',
  pushRouteName: pageRoutes.LOG_IN,
};

export const sideBarLinksData = [
  {
    title: 'Request Feedback',
    label: 'MW',
    link: pageRoutes.MY_WORK,
    icon: MyWorkIcon,
  },
  {
    title: 'Give Feedback',
    label: 'CW',
    link: pageRoutes.COLLEGUES_WORK,
    icon: ColleaguesWorkIcon,
  },
];
