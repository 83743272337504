export const IndustryCheckboxesArray = {
  free:
    'Free, Private, & Secure Assessment of your Performance Management Process',
  pilot: 'Pilot Evalueme at my company or with my team',
  invest: 'Help with R&D by sharing data',
  share: 'Invest in Evalueme',
  introductions:
    'Help with introductions to CEOs, HR Leaders, DEI Leaders, or Advisors',
  join: `Interested in joining Evalueme's team`,
};

export const roleSelectOptions = [
  {
    value: 'Human Resources / People Operations',
    label: 'Human Resources / People Operations',
  },
  { value: 'Company Leadership', label: 'Company Leadership' },
  { value: 'Employee / Consumer', label: 'Employee / Consumer' },
  { value: 'Investor', label: 'Investor' },
  { value: 'Media / Journalist', label: 'Media / Journalist' },
  { value: 'Other', label: 'Other' },
];

export const industrySelectOptions = [
  { value: 'Aerospace & Defense', label: 'Aerospace & Defense' },
  { value: 'Business Services', label: 'Business Services' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Education', label: 'Education' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Food', label: 'Food' },
  { value: 'Gaming', label: 'Gaming' },
  {
    value: 'Government & Public Administration',
    label: 'Government & Public Administration',
  },
  { value: 'Health Services', label: 'Health Services' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media & Entertainment', label: 'Media & Entertainment' },
  { value: 'Non-profit', label: 'Non-profit' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail', label: 'Retail' },
  {
    value: 'Software or Internet Technology',
    label: 'Software or Internet Technology',
  },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Other', label: 'Other' },
];
