import styled from 'styled-components';

import { LogoSmall } from 'assets';
import { CompanyTitle } from 'elements';

import { colors, space, typography } from 'styles';

export const MobileRoadblockWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${space.XXXL} 35px;
  background-color: ${colors.PRIMARY};
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img.attrs({
  src: LogoSmall,
})`
  max-width: 35px;
`;

export const LogoTitle = styled(CompanyTitle)`
  margin-top: ${space.XXS};
  height: 31px;
  font-size: 21.7px;
`;

export const TextContainer = styled.div`
  margin-top: ${space.SM};
  width: 100%;
  color: ${colors.WHITE};
`;

export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const Description = styled.p`
  margin: ${space.XXS} 0 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: ${typography.size.XS};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
