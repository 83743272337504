import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { CheckboxLabel, Checkbox } from 'elements';

import {
  FormCheckboxContainer,
  CheckboxErrorContainer,
  FormCheckboxWrapper,
} from './FormCheckboxStyles';

function FormCheckbox({ name, label, labelPlacement, checked }) {
  const { setFieldValue, errors, touched } = useFormikContext();

  const handleChecked = (event) => {
    setFieldValue(name, event.target.checked);
  };

  return (
    <FormCheckboxContainer>
      <FormCheckboxWrapper key={`checkbox-form-${name}`}>
        <CheckboxLabel
          name={name}
          control={<Checkbox />}
          label={label}
          labelPlacement={labelPlacement}
          checked={checked}
          onChange={handleChecked}
        />
        {errors[name] && touched[name] && (
          <CheckboxErrorContainer>{errors[name]}</CheckboxErrorContainer>
        )}
      </FormCheckboxWrapper>
    </FormCheckboxContainer>
  );
}

FormCheckbox.defaultProps = {
  labelPlacement: 'end',
  checked: false,
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelPlacement: PropTypes.string,
  checked: PropTypes.bool,
};

export default memo(FormCheckbox);
