import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import Modal from 'modules/Modal';
import ToastProvider from 'modules/Toast';
import { GlobalStyle } from 'styles';
import App from './App';

import configureAppStore from './store';
import './assets/fonts/latofonts.css';
import './assets/fonts/poppins.css';

const history = createBrowserHistory();
const { store, persistor } = configureAppStore({}, history);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ToastProvider>
          <App />
          <GlobalStyle />
          <Modal />
        </ToastProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
